import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.Skewness
 */
export interface Skewness extends Computation._UnivariateComputation {
    // PolyJson type
    type: 'skewness';
}

export namespace Skewness {
    export const type = 'skewness';
}

export function isSkewness(toTest: Computation): toTest is Skewness { return Skewness.type === toTest.type; }

export namespace Skewness {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.Skewness$SkewnessResult
     */
    export interface SkewnessResult extends _AvailableResult {
        value: number;

        // PolyJson type
        type: 'skewness';
    }

    export namespace SkewnessResult {
        export const type = 'skewness';
    }

    export function isSkewnessResult(toTest: ComputationResult): toTest is SkewnessResult { return SkewnessResult.type === toTest.type; }
}
import {DebugCard} from './debug-card';
import {ListMostFrequentValues} from './list-most-frequent-values';
import {SuggestCards} from './suggest-cards';

/**
 * Generated from com.dataiku.dip.eda.worksheets.queries.InteractiveQuery
 */
export interface _InteractiveQuery {}

export type InteractiveQuery = DebugCard | SuggestCards | ListMostFrequentValues;

export namespace InteractiveQuery {
    export const type = ["debug_card" , "suggest_cards" , "list_most_frequent_values"] as const;
}

export namespace InteractiveQuery {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.queries.InteractiveQuery$InteractiveQueryResult
     */
    export interface _InteractiveQueryResult {}

    export type InteractiveQueryResult = SuggestCards.SuggestCardsResult | ListMostFrequentValues.ListMostFrequentValuesResult | DebugCard.DebugCardResult;

    export namespace InteractiveQueryResult {
        export const type = ["suggest_cards" , "list_most_frequent_values" , "debug_card"] as const;
    }

    export function isInteractiveQueryResult(toTest: InteractiveQuery.InteractiveQueryResult): toTest is InteractiveQueryResult { return (InteractiveQueryResult.type as readonly unknown[]).includes(toTest.type); }
}
import {ComputationResult} from './computation-result';
import {_UnavailableResult} from './unavailable-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.NotComputedResult
 */
export interface NotComputedResult extends _UnavailableResult {
    // PolyJson type
    type: 'not_computed';
}

export namespace NotComputedResult {
    export const type = 'not_computed';
}

export function isNotComputedResult(toTest: ComputationResult): toTest is NotComputedResult { return NotComputedResult.type === toTest.type; }
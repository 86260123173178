import {ACF} from './acf';
import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {PACF} from './pacf';
import {_AvailableResult} from './../available-result';
import {_TimeSeriesComputation} from './time-series-computation';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.timeseries.CorrelationFunction
 */
export interface _CorrelationFunction extends _TimeSeriesComputation {}

export type CorrelationFunction = ACF | PACF;

export namespace CorrelationFunction {
    export const type = ["acf" , "pacf"] as const;
}

export function isCorrelationFunction(toTest: Computation): toTest is CorrelationFunction { return (CorrelationFunction.type as readonly unknown[]).includes(toTest.type); }

export namespace CorrelationFunction {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.timeseries.CorrelationFunction$CorrelationFunctionResult
     */
    export interface _CorrelationFunctionResult extends _AvailableResult {
        coefficients: number[];
        confidenceIntervals: number[][];
    }

    export type CorrelationFunctionResult = PACF.PACFResult | ACF.ACFResult;

    export namespace CorrelationFunctionResult {
        export const type = ["pacf" , "acf"] as const;
    }

    export function isCorrelationFunctionResult(toTest: ComputationResult): toTest is CorrelationFunctionResult { return (CorrelationFunctionResult.type as readonly unknown[]).includes(toTest.type); }
}
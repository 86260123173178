import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {UnitRootTestADF} from './unit-root-test-adf';
import {UnitRootTestKPSS} from './unit-root-test-kpss';
import {UnitRootTestZA} from './unit-root-test-za';
import {_AvailableResult} from './../available-result';
import {_TimeSeriesComputation} from './time-series-computation';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTest
 */
export interface _UnitRootTest extends _TimeSeriesComputation {}

export type UnitRootTest = UnitRootTestZA | UnitRootTestKPSS | UnitRootTestADF;

export namespace UnitRootTest {
    export const type = ["unit_root_za" , "unit_root_kpss" , "unit_root_adf"] as const;
}

export function isUnitRootTest(toTest: Computation): toTest is UnitRootTest { return (UnitRootTest.type as readonly unknown[]).includes(toTest.type); }

export namespace UnitRootTest {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTest$UnitRootTestResult
     */
    export interface _UnitRootTestResult extends _AvailableResult {
        criticalValues: {[key: string]: number};
        nObservations: number;
        pValue: number;
        statistic: number;
        usedLag: number;
    }

    export type UnitRootTestResult = UnitRootTestADF.UnitRootTestADFResult | UnitRootTestZA.UnitRootTestZAResult | UnitRootTestKPSS.UnitRootTestKPSSResult;

    export namespace UnitRootTestResult {
        export const type = ["unit_root_adf" , "unit_root_za" , "unit_root_kpss"] as const;
    }

    export function isUnitRootTestResult(toTest: ComputationResult): toTest is UnitRootTestResult { return (UnitRootTestResult.type as readonly unknown[]).includes(toTest.type); }
}
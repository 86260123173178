import {CardResult} from './card-result';
import {Card} from './card';
import {UnitRootTestADF} from './../../compute/computations/timeseries/unit-root-test-adf';
import {UnitRootTestCard, _UnitRootTestCard} from './unit-root-test-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.UnitRootTestADFCard
 */
export interface UnitRootTestADFCard extends _UnitRootTestCard {
    regressionMode: UnitRootTestADF.RegressionMode;

    // PolyJson type
    type: 'unit_root_test_adf';
}

export namespace UnitRootTestADFCard {
    export const type = 'unit_root_test_adf';
}

export function isUnitRootTestADFCard(toTest: Card): toTest is UnitRootTestADFCard { return UnitRootTestADFCard.type === toTest.type; }

export namespace UnitRootTestADFCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.UnitRootTestADFCard$UnitRootTestADFCardResult
     */
    export interface UnitRootTestADFCardResult extends UnitRootTestCard._UnitRootTestCardResult {
        // PolyJson type
        type: 'unit_root_test_adf';
    }

    export namespace UnitRootTestADFCardResult {
        export const type = 'unit_root_test_adf';
    }

    export function isUnitRootTestADFCardResult(toTest: CardResult): toTest is UnitRootTestADFCardResult { return UnitRootTestADFCardResult.type === toTest.type; }
}
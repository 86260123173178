import {AllFilter} from './all-filter';
import {AndFilter} from './and-filter';
import {AnumFilter} from './anum-filter';
import {IntervalFilter} from './interval-filter';
import {MissingValueFilter} from './missing-value-filter';
import {NotFilter} from './not-filter';

/**
 * Generated from com.dataiku.dip.eda.compute.filtering.Filter
 */
export interface _Filter {
    name?: string | null;
}

export type Filter = AnumFilter | AllFilter | NotFilter | MissingValueFilter | AndFilter | IntervalFilter;

export namespace Filter {
    export const type = ["anum" , "all" , "not" , "missing" , "and" , "interval"] as const;
}
import {AuditTrailTargetSettings, _AuditTrailTargetSettings} from './audit-trail-target-settings';

/**
 * Generated from com.dataiku.dip.security.audit.model.KafkaTargetSettings
 */
export interface KafkaTargetSettings extends _AuditTrailTargetSettings {
    connection: string;
    topic: string;

    // PolyJson type
    type: 'KAFKA';
}

export namespace KafkaTargetSettings {
    export const type = 'KAFKA';
}

export function isKafkaTargetSettings(toTest: AuditTrailTargetSettings): toTest is KafkaTargetSettings { return KafkaTargetSettings.type === toTest.type; }
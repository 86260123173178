import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {Grouping} from './../../grouping/grouping';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.MoodTestNSamp
 */
export interface MoodTestNSamp extends Computation._UnivariateComputation {
    grouping: Grouping;

    // PolyJson type
    type: 'mood_test_nsamp';
}

export namespace MoodTestNSamp {
    export const type = 'mood_test_nsamp';
}

export function isMoodTestNSamp(toTest: Computation): toTest is MoodTestNSamp { return MoodTestNSamp.type === toTest.type; }

export namespace MoodTestNSamp {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.MoodTestNSamp$MoodTestNSampResult
     */
    export interface MoodTestNSampResult extends _AvailableResult {
        pvalue: number;
        statistic: number;

        // PolyJson type
        type: 'mood_test_nsamp';
    }

    export namespace MoodTestNSampResult {
        export const type = 'mood_test_nsamp';
    }

    export function isMoodTestNSampResult(toTest: ComputationResult): toTest is MoodTestNSampResult { return MoodTestNSampResult.type === toTest.type; }
}
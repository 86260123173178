import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Column } from '@shared/components/feed-image-modal/feed-image-modal.component';
import { FeedImageModalService } from '@shared/services/feed-image-modal.service';
import { filterNonNull } from 'dku-frontend-core';
import { combineLatest, Observable } from 'rxjs';
import { DeephubImageClassificationReportPainterService } from '@features/deephub/image-classification/report/services/deephub-image-classification-report-painter.service';
import { BasePainterOptions } from '@shared/models/painter';
import { PainterService } from '@shared/services/painter.service';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { DeephubImageClassificationReportCellData } from '../services/deephub-image-classification-report-data-fetcher.service';

class FormattedPrediction {
    category?: string;
    proba: number;
    title: string;
    color: string | undefined;
    others: boolean;
}

@UntilDestroy()
@Component({
    selector: 'deephub-image-classification-report-image-feed-modal',
    templateUrl: './deephub-image-classification-report-image-feed-modal.component.html',
    styleUrls: ['./deephub-image-classification-report-image-feed-modal.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        DeephubImageClassificationReportPainterService,
        { provide: PainterService, useExisting: DeephubImageClassificationReportPainterService },
    ]
})
export class DeephubImageClassificationReportImageFeedModalComponent {
    readonly NUM_SHOWN_CLASSES = 7;
    formattedPredictions$: Observable<FormattedPrediction[]>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            imageId: number,
            columns: Column[]
        },
        private feedImageModalService: FeedImageModalService,
        private colorMapService: ColorMapContextService

    ) {
        combineLatest([
            this.feedImageModalService.imageId$,
            this.feedImageModalService.canvas$
        ]).pipe(
            filterNonNull(),
        );

        this.formattedPredictions$ = this.feedImageModalService.image$.pipe(
           distinctUntilChanged((a, b) => a.cellData.imageId === b.cellData.imageId), // not really necessary but just to ensure it only gets called once per image
           untilDestroyed(this),
           map(image => this.formatPredictions((image.cellData as DeephubImageClassificationReportCellData).probabilities))
        );
    }

    private getCategoryName (prefixedCategory: string): string {
        return prefixedCategory.replace('proba_', '');
    }

    private formatTitle(probas: [string, number][]) : string {
        return probas.map(proba => `${this.getCategoryName(proba[0])} (${(proba[1]*100).toFixed(1)}%)`).join("\n");
    }

    getCategoryColor (category: string) : string | undefined {
        return this.colorMapService.mapping.get(category);
    }

    formatPredictions(probabilities: Map<string, number>) : FormattedPrediction[]
    {
        const sortedProbas = Array.from(probabilities).sort((a, b) => (b[1] - a[1]));
        const formattedPredictions: FormattedPrediction[] = [];
        for (var i = 0; i < Math.min(this.NUM_SHOWN_CLASSES, sortedProbas.length); i++) {
            const catName = this.getCategoryName(sortedProbas[i][0]);
            formattedPredictions.push({category: catName,
                                       proba: sortedProbas[i][1],
                                       title: this.formatTitle([sortedProbas[i]]),
                                       others: false,
                                       color: this.getCategoryColor(catName)});
        }
        if (sortedProbas.length > this.NUM_SHOWN_CLASSES){
            // Aggregate the rest of the categories/probas into a single item.
            const otherProbas = sortedProbas.slice(this.NUM_SHOWN_CLASSES);
            const otherAggProba = otherProbas.reduce((sumProbas, nextValue) => sumProbas + nextValue[1], 0);
            formattedPredictions.push({proba: otherAggProba,
                                       title: this.formatTitle(otherProbas),
                                       others: true,
                                       color: '#DDDDDD'});
        }

        return formattedPredictions;
    }
}


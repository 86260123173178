import {AbstractCardContainer, _AbstractCardContainer} from './common/abstract-card-container';
import {CardResult} from './card-result';
import {Card} from './card';
import {Filter} from './../../compute/filtering/filter';

export namespace WorksheetRootCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.WorksheetRootCard$WorksheetRootCardResult
     */
    export interface WorksheetRootCardResult extends AbstractCardContainer._AbstractCardContainerResult {
        // PolyJson type
        type: 'worksheet_root';
    }

    export namespace WorksheetRootCardResult {
        export const type = 'worksheet_root';
    }

    export function isWorksheetRootCardResult(toTest: CardResult): toTest is WorksheetRootCardResult { return WorksheetRootCardResult.type === toTest.type; }
}

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.WorksheetRootCard
 */
export interface WorksheetRootCard extends _AbstractCardContainer {
    confidenceLevel: number;
    highlightFilter?: Filter | null;
    showConfidenceInterval: boolean;

    // PolyJson type
    type: 'worksheet_root';
}

export namespace WorksheetRootCard {
    export const type = 'worksheet_root';
}

export function isWorksheetRootCard(toTest: Card): toTest is WorksheetRootCard { return WorksheetRootCard.type === toTest.type; }
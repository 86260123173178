import { Component, ChangeDetectionStrategy, Input, ElementRef, ViewChild, AfterViewInit, ChangeDetectorRef, Output, EventEmitter, SimpleChanges, OnInit } from '@angular/core';
import { fabric } from 'fabric';
import { CellData, ImagesDataFetcherService } from '@shared/services/images-data-fetcher.service';
import { PainterService } from "@shared/services/painter.service";
import { StaticCanvas } from 'fabric/fabric-impl';
import { WindowService } from 'dku-frontend-core';
import { debounceTime } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BasePainterOptions } from '@shared/models/painter';

@UntilDestroy()
@Component({
  selector: 'feed-image-modal-canvas',
  templateUrl: './feed-image-modal-canvas.component.html',
  styleUrls: ['./feed-image-modal-canvas.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedImageModalCanvasComponent implements OnInit, AfterViewInit {
    @ViewChild('canvasEl') canvasEl: ElementRef;
    @ViewChild('wrapperEl') wrapperEl: ElementRef;
    @Input() cellData: CellData;
    @Input() imageId: number; // in case cell has no data yet
    @Input() options: BasePainterOptions;
    @Output() imageStatusChanged = new EventEmitter<boolean>();
    @Output() canvasUpdate = new EventEmitter<StaticCanvas>();

    public hasImage: boolean;
    public hasLoaded: boolean;
    public margin = 16;
    public loaderBaseHeight: number;
    private canvas: StaticCanvas;
    private image: HTMLImageElement;
    private readonly MAX_SCALE = 3;

    constructor(
        private dataFetcher: ImagesDataFetcherService,
        private canvasPainter: PainterService,
        private windowService: WindowService,
        private cd: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.windowService.resize$.pipe(
            untilDestroyed(this),
            debounceTime(500)
        ).subscribe(_ => {
            this.drawCanvasElements();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.cellData) {
            // redraw canvas if image is different
            if (changes.cellData?.currentValue?.itemPath !== changes.cellData?.previousValue?.itemPath) {
                this.drawCanvas();
            } else { // if we're just resizing, don't redraw entire canvas
                this.drawCanvasElements();
            }

            this.cd.markForCheck();
        }
    }

    ngAfterViewInit() {
        this.loaderBaseHeight = Math.min(this.wrapperEl.nativeElement.clientHeight, 400);

        this.canvas = new fabric.StaticCanvas(this.canvasEl.nativeElement);
    }

    drawCanvas(): void {
        this.resetCanvas();

        const imgUrl = this.dataFetcher.getImagePath(this.cellData.itemPath);

        this.image = fabric.util.createImage();
        this.image.src = imgUrl;
        this.image.onload = this.image.onerror = this.drawCanvasElements.bind(this);
    }

    drawCanvasElements() {
        const oImg = new fabric.Image(this.image, {});
        this.hasLoaded = true;

        const imgOriginalWidth: number = oImg.getScaledWidth();
        const imgOriginalHeight: number = oImg.getScaledHeight();

        if (this.canvas) {
            this.clearCanvas();
        }

        if (!imgOriginalWidth || !imgOriginalHeight) {
            this.setImageStatus(false);
        } else {
            this.setImageStatus(true);

            const containerWidth = this.wrapperEl.nativeElement.clientWidth;
            const containerHeight = this.wrapperEl.nativeElement.clientHeight;
            const imgScaleX = containerWidth / imgOriginalWidth;
            const imgScaleY = containerHeight / imgOriginalHeight;
            const imgScale = Math.min(imgScaleX, imgScaleY, this.MAX_SCALE);
            const imgPosition = {
                scale: imgScale,
                left: 0,
                top: 0
            };

            this.canvas.setBackgroundImage(oImg, this.canvas.renderAll.bind(this.canvas), {
                scaleX: imgPosition.scale,
                scaleY: imgPosition.scale,
                top: imgPosition.top,
                left: imgPosition.left
            });

            // Shrink canvas to image
            this.canvas.setWidth(oImg.getScaledWidth());
            this.canvas.setHeight(oImg.getScaledHeight());

            this.canvasPainter.paintForModal(this.cellData, this.canvas, imgPosition, this.options);
            this.canvasUpdate.emit(this.canvas);
        }

        this.cd.markForCheck();
    }

    resetCanvas() {
        this.hasImage = false;
        this.hasLoaded = false;

        if (this.canvas) {
            this.clearCanvas();
        }
    }

    setImageStatus(hasImage: boolean) {
        this.hasImage = hasImage;
        this.imageStatusChanged.emit(hasImage);
    }

    clearCanvas() {
        this.canvas.setDimensions({
            width: 0,
            height: 0
        });
        this.canvas.clear();
    }
}

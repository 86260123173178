import { Observable } from 'rxjs';
import { DkuHttpService } from 'dku-frontend-core';
import { Injectable } from '@angular/core';
import { SerializedMemTableV2 } from '@model-main/shaker/server/serialized-mem-table-v2';
import { SerializedTableChunk } from '@model-main/shaker/server/serialized-table-chunk';
import { DataAugmentationService, DeepHubPreTrainModelingParams } from 'src/generated-sources';

@Injectable({ providedIn: 'root' })
export class PrivateAnalysisAPI {
    constructor(
        private dkuHttp: DkuHttpService,
    ) { }

    getRandomImagePaths(projectKey: string, analysisId: string, mlTaskId: string, numImagePaths: number): Observable<String[]> {
        return this.dkuHttp.request('POST', '/analysis/pml/get-random-image-paths', { projectKey, analysisId, mlTaskId, numImagePaths });
    }

    getAugmentedImages(projectKey: string, analysisId: string, mlTaskId: string, augmentationParams: DeepHubPreTrainModelingParams.ImageAugmentationParams,
        imagePath: string, numAugmentedVersions: number, applyMaxTransform: boolean, augmentationType?: DataAugmentationService.AugmentationType): Observable<DataAugmentationService.AugmentedImage[]> {
        return this.dkuHttp.request('POST', '/analysis/pml/get-augmented-images', { projectKey, analysisId, mlTaskId,
            augmentationParams: JSON.stringify(augmentationParams), imagePath, numAugmentedVersions, augmentationType, applyMaxTransform});
    }

    refreshPMLImagesDataSample(projectKey: string, analysisId: string, mlTaskId: string, nbRows: number, filters?: any): Observable<SerializedMemTableV2> {
        return this.dkuHttp.request('POST', '/analysis/pml/refresh-images-data-sample', { projectKey, analysisId, mlTaskId, nbRows, filters: JSON.stringify(filters) });
    }

    getPMLImagesDataChunk(projectKey: string, analysisId: string, mlTaskId: string, firstRow: number, nbRows?: number, filters?: any): Observable<SerializedTableChunk> {
        return this.dkuHttp.request('POST', '/analysis/pml/get-images-data-chunk', { projectKey, analysisId, mlTaskId, firstRow, nbRows, filters: JSON.stringify(filters) });
    }

    refreshPredictedImagesDataSample(fullModelId: string, nbRows: number, filters?: any): Observable<SerializedMemTableV2> {
        return this.dkuHttp.request('POST', '/analysis/predicted/refresh-images-data-sample', { fullModelId, nbRows, filters: JSON.stringify(filters) });
    }

    getPredictedImagesDataChunk(fullModelId: string, firstRow: number, nbRows: number, filters?: any): Observable<SerializedTableChunk> {
        return this.dkuHttp.request('POST', '/analysis/predicted/get-images-data-chunk', { fullModelId, firstRow, nbRows, filters: JSON.stringify(filters) });
    }

}

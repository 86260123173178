<div [qa]="'eda-card-help-content'">
    <tztest1-samp-card-help
        [params]="params"
        *ngIf="params.type == 'ttest_ztest_1samp'"
        [readOnly]="readOnly"
        [extendedActions]="extendedActions"
        (action)="action.emit($event)"
    ></tztest1-samp-card-help>

    <shapiro-normality-test-card-help
        [params]="params"
        *ngIf="params.type == 'shapiro'"
        [readOnly]="readOnly"
        [extendedActions]="extendedActions"
        (action)="action.emit($event)"
    ></shapiro-normality-test-card-help>

    <ks-test2-samp-card-help
        [params]="params"
        *ngIf="params.type == 'ks_test_2samp'"
        [readOnly]="readOnly"
        [extendedActions]="extendedActions"
        (action)="action.emit($event)"
    ></ks-test2-samp-card-help>

    <sign-test1-samp-card-help
        [params]="params"
        *ngIf="params.type == 'sign_test_1samp'"
        [readOnly]="readOnly"
        [extendedActions]="extendedActions"
        (action)="action.emit($event)"
    ></sign-test1-samp-card-help>

    <chi2-ind-test-card-help
        [params]="params"
        *ngIf="params.type == 'chi2_independence_test'"
        [readOnly]="readOnly"
        [extendedActions]="extendedActions"
        (action)="action.emit($event)"
    ></chi2-ind-test-card-help>

    <generic-nsamp-mood-test-help
        [params]="params"
        *ngIf="params.type == 'mood_nsamp'"
        [readOnly]="readOnly"
        [extendedActions]="extendedActions"
        (action)="action.emit($event)"
    ></generic-nsamp-mood-test-help>

    <generic-nsamp-mood-test-help
        [params]="params"
        *ngIf="params.type == 'mood_test_2samp'"
        [readOnly]="readOnly"
        [extendedActions]="extendedActions"
        (action)="action.emit($event)"
    ></generic-nsamp-mood-test-help>

    <generic-nsamp-mood-test-help
        [params]="params"
        *ngIf="params.type == 'pairwise_mood'"
        [readOnly]="readOnly"
        [extendedActions]="extendedActions"
        (action)="action.emit($event)"
    ></generic-nsamp-mood-test-help>

    <one-way-anova-card-help
        [params]="params"
        *ngIf="params.type == 'oneway_anova'"
        [readOnly]="readOnly"
        [extendedActions]="extendedActions"
        (action)="action.emit($event)"
    ></one-way-anova-card-help>

    <generic-nsamp-ttest-card-help
        [params]="params"
        *ngIf="params.type == 'ttest_2samp'"
        [readOnly]="readOnly"
        [extendedActions]="extendedActions"
        (action)="action.emit($event)"
    ></generic-nsamp-ttest-card-help>

    <generic-nsamp-ttest-card-help
        [params]="params"
        *ngIf="params.type == 'pairwise_ttest'"
        [readOnly]="readOnly"
        [extendedActions]="extendedActions"
        (action)="action.emit($event)"
    ></generic-nsamp-ttest-card-help>

    <unit-root-test-adf-card-help
        [params]="params"
        *ngIf="params.type == 'unit_root_test_adf'"
        [readOnly]="readOnly"
        [extendedActions]="extendedActions"
        (action)="action.emit($event)"
    ></unit-root-test-adf-card-help>

    <unit-root-test-za-card-help
        [params]="params"
        *ngIf="params.type == 'unit_root_test_za'"
        [readOnly]="readOnly"
        [extendedActions]="extendedActions"
        (action)="action.emit($event)"
    ></unit-root-test-za-card-help>

    <unit-root-test-kpss-card-help
        [params]="params"
        *ngIf="params.type == 'unit_root_test_kpss'"
        [readOnly]="readOnly"
        [extendedActions]="extendedActions"
        (action)="action.emit($event)"
    ></unit-root-test-kpss-card-help>

    <durbin-watson-card-help
        [params]="params"
        *ngIf="params.type == 'durbin_watson'"
        [readOnly]="readOnly"
        [extendedActions]="extendedActions"
        (action)="action.emit($event)"
    ></durbin-watson-card-help>

    <mann-kendall-test-card-help
        [params]="params"
        *ngIf="params.type == 'mann_kendall_test'"
        [readOnly]="readOnly"
        [extendedActions]="extendedActions"
        (action)="action.emit($event)"
    ></mann-kendall-test-card-help>

    <acf-plot-card-help
        [params]="params"
        *ngIf="params.type == 'acf_plot'"
        [readOnly]="readOnly"
        [extendedActions]="extendedActions"
        (action)="action.emit($event)"
    ></acf-plot-card-help>
</div>

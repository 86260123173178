import { Injectable } from '@angular/core';
import { DeepHubColumnFormat } from "src/generated-sources";
import { UntilDestroy } from '@ngneat/until-destroy';
import { AbstractDeephubDesignDataFetcherService, DeephubDesignCellData } from '@features/deephub/computer-vision/design/services/abstract-deephub-design-data-fetcher.service';

export class DeephubObjectDetectionDesignCellData implements DeephubDesignCellData {
    constructor(
        public itemPath: string,
        public target: DeepHubColumnFormat.ObjectDetectionTargetItem[],
        public imageId: number
    ) {}

    listCategories(): string[] {
        return this.target?.map(t => t.category);
    }
}
@UntilDestroy()
@Injectable()
export class DeephubObjectDetectionDesignDataFetcherService extends AbstractDeephubDesignDataFetcherService {
    rowToCellData(rowContent: string[], i: number, j: number): DeephubObjectDetectionDesignCellData {
        return new DeephubObjectDetectionDesignCellData(
            rowContent[this.dataColumns.itemPath.index!],
            JSON.parse(rowContent[this.dataColumns.target.index!]),
            i
        );
    }
}

import { Card, Variable, SerializedDataset } from 'src/generated-sources';
import { AnyLoc, Worksheet, Sample, CardResult, WorksheetRootCard, ComputationResult } from 'src/generated-sources';
import { Observable, combineLatest, NEVER } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import deepEqual from 'fast-deep-equal';
import { deepDistinctUntilChanged, combineLatestObject } from 'dku-frontend-core';
import { APIError } from '@core/dataiku-api/api-error';
import { columnsToVariables } from '../schema-utils';
import { resolveSmartName } from '@utils/loc';


// Store the results of card
export interface CachedComputedCard {
    cardParams: Card;
    cardResult: CardResult;
    sampleId: string;
}

// Store state related to the current worksheet
export interface State {
    // Pointer to the (maybe not yet loaded) worksheet
    worksheetLoc?: AnyLoc;

    // Temporary worksheet containing pending user modifications (not fixed up yet)
    // - The 'dirtyWorksheet' is updated by user actions
    // - The WorksheetSaver will take care of updating the 'worksheet' (after it has been saved & fixed)
    dirtyWorksheet?: Worksheet;

    // Current worksheet (fixed up by the backend & consistent with current results)
    // - This worksheet is never impacted by user actions directly
    // - It is updated by the WorksheetSaver or WorksheetLoader processes only
    worksheet?: Worksheet;

    // Details about the current sample
    // - Managed by the SampleLoader process
    // - Note that the sample is only asked if necessary (ie. when there are cards to compute or explicitely requested)
    sample?: Sample;

    // Card which should require focus (when a card is focused => we scroll to id)
    // - 'focusedCardId' is set when a top level card is newly added
    // - When card is displayed in the UI with results, the component triggers a scroll
    // - After scroll is done, the component will reset the 'focusedCardId'
    focusedCardId?: string;

    // Store the results of each top level cards (instead of working with a global WorksheetRootCardResult)
    // - 'results' are generated by the WorksheetComputer process
    // - 'results' **MUST** be consistent with the cards of the current 'worksheet' at all time
    results: { [cardId: string]: CachedComputedCard };

    // List of card IDs which need to be computed
    // - 'requestedCardIds' is driven by the CollapsingWatcher process (to compute expanded cards only)
    requestedCardIds: string[];

    // Store a worksheet-global error
    // - Any process can push a worksheet-global error (eg. network error, backend failure, etc)
    error?: APIError;

    // Dataset associated to the worksheet
    // - 'dataset' is managed by the DatasetLoader process
    dataset?: SerializedDataset;

    // Flag indicating that the sample has been requested
    // (without this flag, the sample is not built automatically until there is a card to compute)
    sampleExternallyRequested: boolean;
}

export const INITIAL_STATE: State = {
    requestedCardIds: [],
    results: {},
    sampleExternallyRequested: false
};

export type Transition = (state: State) => State;

export type Process = Observable<Transition>;

// Extract nested properties from the global worksheet state
export class StateSelectors {
    constructor(public state$: Observable<State>) { }

    getRequestedCards() {
        return combineLatest([this.getRequestedCardIds(), this.getWorksheet()]).pipe(
            map(([ids, worksheet]) => {
                if (!worksheet) { return []; }
                return worksheet.rootCard!.cards.filter(card => ids.includes(card.id));
            }),
            deepDistinctUntilChanged()
        );
    }

    getCardsToCompute() {
        return combineLatest([
            this.getComputedCards(),
            this.getRequestedCards()
        ]).pipe(map(([computedCards, requestedCardIds]) => {
            return requestedCardIds.filter((requestedCard) => {
                const computedCard = computedCards[requestedCard.id];
                return !computedCard || !deepEqual(computedCard.cardParams, requestedCard);
            });
        }));
    }

    getSample(): Observable<Sample | undefined> {
        return this.state$.pipe(map(state => state.sample), deepDistinctUntilChanged());
    }

    isSampleExternallyRequested() : Observable<boolean> {
        return this.state$.pipe(map(state => state.sampleExternallyRequested), deepDistinctUntilChanged());
    }

    getLoc(): Observable<AnyLoc | undefined> {
        return this.state$.pipe(map(state => state.worksheetLoc), deepDistinctUntilChanged());
    }

    getWorksheetLoc(): Observable<AnyLoc | undefined> {
        return this.state$.pipe(map(state => state.worksheetLoc), deepDistinctUntilChanged());
    }

    getWorksheet(): Observable<Worksheet | undefined> {
        return this.state$.pipe(map(state => state.worksheet), deepDistinctUntilChanged());
    }

    getRootCard(): Observable<WorksheetRootCard | undefined> {
        return this.getWorksheet().pipe(map(worksheet => worksheet && worksheet.rootCard!));
    }

    getRequestedCardIds(): Observable<string[]> {
        return this.state$.pipe(map(state => state.requestedCardIds), deepDistinctUntilChanged());
    }

    getError(): Observable<APIError | undefined> {
        return this.state$.pipe(map(state => state.error), distinctUntilChanged());
    }

    getDatasetLoc(): Observable<AnyLoc | undefined> {
        return this.state$.pipe(
            map(state => state.worksheet ? resolveSmartName(
                state.worksheet.projectKey,
                state.worksheet.dataSpec.inputDatasetSmartName
            ) : undefined),
            deepDistinctUntilChanged()
        );
    }

    getDataset(): Observable<SerializedDataset | undefined> {
        return this.state$.pipe(map(state => state.dataset), distinctUntilChanged());
    }

    getFocusedCardId(): Observable<string | undefined> {
        return this.state$.pipe(map(state => state.focusedCardId), distinctUntilChanged());
    }

    getRootCardResults(): Observable<WorksheetRootCard.WorksheetRootCardResult | undefined> {
        return combineLatestObject({
            worksheet: this.getWorksheet(),
            computedResults: this.getComputedResults()
        }).pipe(
            map(({ worksheet, computedResults }) => {
                if (!worksheet) {
                    return;
                }
                const fakeResultCard: WorksheetRootCard.WorksheetRootCardResult = {
                    type: 'worksheet_root',
                    // This is fake, backend cannot process multiple cards at once
                    computationCount: -1,
                    failedComputationsCount: -1,
                    notComputedCount: -1,
                    results: worksheet.rootCard!.cards.map((card): CardResult => {
                        if (computedResults[card.id]) {
                            return computedResults[card.id].cardResult;
                        }
                        return {
                            type: 'unavailable',
                            reason: CardResult.UnavailabilityReason.NOT_COMPUTED,
                            // This is a fake result
                            computationCount: -1,
                            failedComputationsCount: -1,
                            notComputedCount: -1
                        };
                    })
                };

                return fakeResultCard;
            })
        );
    }

    getComputationResults(v: any): Observable<ComputationResult> {
        return NEVER;
    }

    getComputedResults() {
        return this.state$.pipe(map(state => state.results));
    }

    getDataSpec() {
        return this.getWorksheet().pipe(
            map(worksheet => worksheet && worksheet.dataSpec),
            deepDistinctUntilChanged()
        );
    }

    getComputedCards() {
        return this.state$.pipe(
            map(state => state.results),
            deepDistinctUntilChanged()
        );
    }

    availableVariables(): Observable<Variable[]> {
        return combineLatest([this.getSample(), this.getDataset()]).pipe(
            map(([sample, dataset]) => {
                const schemaSource = sample || dataset;
                if (!schemaSource) {
                    return [];
                }
                return columnsToVariables(schemaSource.schema.columns || []);
            })
        );
    }
}

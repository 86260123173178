import {BivariateHeaderCard} from './../bivariate_header/bivariate-header-card';
import {Card, _Card} from './../card';
import {CardResult, _CardResult} from './../card-result';
import {ColumnCard} from './column-card';
import {UnivariateHeaderCard} from './../univariate_header/univariate-header-card';
import {WorksheetRootCard} from './../worksheet-root-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.common.AbstractCardContainer
 */
export interface _AbstractCardContainer extends _Card {
    cards: Card[];
}

export type AbstractCardContainer = BivariateHeaderCard | WorksheetRootCard | UnivariateHeaderCard | ColumnCard;

export namespace AbstractCardContainer {
    export const type = ["bivariate_header" , "worksheet_root" , "univariate_header" , "column_card"] as const;
}

export function isAbstractCardContainer(toTest: Card): toTest is AbstractCardContainer { return (AbstractCardContainer.type as readonly unknown[]).includes(toTest.type); }

export namespace AbstractCardContainer {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.common.AbstractCardContainer$AbstractCardContainerResult
     */
    export interface _AbstractCardContainerResult extends _CardResult {
        results: CardResult[];
    }

    export type AbstractCardContainerResult = UnivariateHeaderCard.UnivariateHeaderCardResult | BivariateHeaderCard.BivariateHeaderCardResult | WorksheetRootCard.WorksheetRootCardResult | ColumnCard.ColumnCardResult;

    export namespace AbstractCardContainerResult {
        export const type = ["univariate_header" , "bivariate_header" , "worksheet_root" , "column_card"] as const;
    }

    export function isAbstractCardContainerResult(toTest: CardResult): toTest is AbstractCardContainerResult { return (AbstractCardContainerResult.type as readonly unknown[]).includes(toTest.type); }
}
import {Distribution, _Distribution} from './distribution';

/**
 * Generated from com.dataiku.dip.eda.compute.distributions.Binomial
 */
export interface Binomial extends _Distribution {
    n: number;

    // PolyJson type
    type: 'binomial';
}

export namespace Binomial {
    export const type = 'binomial';
}

export function isBinomial(toTest: Distribution): toTest is Binomial { return Binomial.type === toTest.type; }

export namespace Binomial {
    /**
     * Generated from com.dataiku.dip.eda.compute.distributions.Binomial$FittedBinomial
     */
    export interface FittedBinomial extends Distribution._FittedDistribution {
        n: number;
        p: number;

        // PolyJson type
        type: 'binomial';
    }

    export namespace FittedBinomial {
        export const type = 'binomial';
    }

    export function isFittedBinomial(toTest: Distribution.FittedDistribution): toTest is FittedBinomial { return FittedBinomial.type === toTest.type; }
}
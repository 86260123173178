import {CardResult} from './card-result';
import {Card} from './card';
import {PACF} from './../../compute/computations/timeseries/pacf';
import {TimeSeriesCard, _TimeSeriesCard} from './time-series-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.ACFPlotCard
 */
export interface ACFPlotCard extends _TimeSeriesCard {
    adjusted: boolean;
    confidenceLevel: number;
    isPartial: boolean;
    nLags?: number | null;
    pacfMethod: PACF.Method;
    showSummary: boolean;

    // PolyJson type
    type: 'acf_plot';
}

export namespace ACFPlotCard {
    export const type = 'acf_plot';
}

export function isACFPlotCard(toTest: Card): toTest is ACFPlotCard { return ACFPlotCard.type === toTest.type; }

export namespace ACFPlotCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.ACFPlotCard$ACFPlotCardResult
     */
    export interface ACFPlotCardResult extends TimeSeriesCard._TimeSeriesCardResult {
        coefficients: number[];
        confidenceIntervals: number[][];

        // PolyJson type
        type: 'acf_plot';
    }

    export namespace ACFPlotCardResult {
        export const type = 'acf_plot';
    }

    export function isACFPlotCardResult(toTest: CardResult): toTest is ACFPlotCardResult { return ACFPlotCardResult.type === toTest.type; }
}
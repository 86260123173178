import {CardResult, _CardResult} from './../card-result';
import {Card} from './../card';
import {CategoricalHistogramCard} from './categorical-histogram-card';
import {_UnivariateCard} from './../univariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateFrequencyTableCard
 */
export interface UnivariateFrequencyTableCard extends _UnivariateCard {
    maxValues: number;

    // PolyJson type
    type: 'univariate_frequency_table';
}

export namespace UnivariateFrequencyTableCard {
    export const type = 'univariate_frequency_table';
}

export function isUnivariateFrequencyTableCard(toTest: Card): toTest is UnivariateFrequencyTableCard { return UnivariateFrequencyTableCard.type === toTest.type; }

export namespace UnivariateFrequencyTableCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateFrequencyTableCard$UnivariateFrequencyTableCardResult
     */
    export interface UnivariateFrequencyTableCardResult extends _CardResult {
        distinctCount: number;
        frequencies: CategoricalHistogramCard.HistogramData;

        // PolyJson type
        type: 'univariate_frequency_table';
    }

    export namespace UnivariateFrequencyTableCardResult {
        export const type = 'univariate_frequency_table';
    }

    export function isUnivariateFrequencyTableCardResult(toTest: CardResult): toTest is UnivariateFrequencyTableCardResult { return UnivariateFrequencyTableCardResult.type === toTest.type; }
}
import {Computation, _Computation} from './../computation';
import {ComputationResult} from './../computation-result';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.common.MultiComputation
 */
export interface MultiComputation extends _Computation {
    computations: Computation[];

    // PolyJson type
    type: 'multi';
}

export namespace MultiComputation {
    export const type = 'multi';
}

export function isMultiComputation(toTest: Computation): toTest is MultiComputation { return MultiComputation.type === toTest.type; }

export namespace MultiComputation {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.common.MultiComputation$MultiComputationResult
     */
    export interface MultiComputationResult extends _AvailableResult {
        results: ComputationResult[];

        // PolyJson type
        type: 'multi';
    }

    export namespace MultiComputationResult {
        export const type = 'multi';
    }

    export function isMultiComputationResult(toTest: ComputationResult): toTest is MultiComputationResult { return MultiComputationResult.type === toTest.type; }
}
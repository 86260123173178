import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.Mean
 */
export interface Mean extends Computation._UnivariateComputation {
    confidence?: number | null;

    // PolyJson type
    type: 'mean';
}

export namespace Mean {
    export const type = 'mean';
}

export function isMean(toTest: Computation): toTest is Mean { return Mean.type === toTest.type; }

export namespace Mean {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.Mean$MeanResult
     */
    export interface MeanResult extends _AvailableResult {
        lower?: number | null;
        upper?: number | null;
        value: number;

        // PolyJson type
        type: 'mean';
    }

    export namespace MeanResult {
        export const type = 'mean';
    }

    export function isMeanResult(toTest: ComputationResult): toTest is MeanResult { return MeanResult.type === toTest.type; }
}
import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.Sum
 */
export interface Sum extends Computation._UnivariateComputation {
    confidence?: number | null;

    // PolyJson type
    type: 'sum';
}

export namespace Sum {
    export const type = 'sum';
}

export function isSum(toTest: Computation): toTest is Sum { return Sum.type === toTest.type; }

export namespace Sum {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.Sum$SumResult
     */
    export interface SumResult extends _AvailableResult {
        lower?: number | null;
        upper?: number | null;
        value: number;

        // PolyJson type
        type: 'sum';
    }

    export namespace SumResult {
        export const type = 'sum';
    }

    export function isSumResult(toTest: ComputationResult): toTest is SumResult { return SumResult.type === toTest.type; }
}
import {BinningMode} from './../../models/binning-mode';
import {BoxPlotFragment} from './../fragments/box-plot-fragment';
import {CardResult, _CardResult} from './../card-result';
import {Card} from './../card';
import {Filter} from './../../../compute/filtering/filter';
import {NumericalHistogramFragment} from './../fragments/numerical-histogram-fragment';
import {_UnivariateCard} from './../univariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.univariate_header.NumericalHistogramCard
 */
export interface NumericalHistogramCard extends _UnivariateCard {
    binningMode: BinningMode;
    customBinningBoundaries: number[];
    highlightFilter?: Filter | null;
    nbBins: number;
    showBoxPlot: boolean;
    showHistogram: boolean;

    // PolyJson type
    type: 'numerical_histogram';
}

export namespace NumericalHistogramCard {
    export const type = 'numerical_histogram';
}

export function isNumericalHistogramCard(toTest: Card): toTest is NumericalHistogramCard { return NumericalHistogramCard.type === toTest.type; }

export namespace NumericalHistogramCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.univariate_header.NumericalHistogramCard$NumericalHistogramCardResult
     */
    export interface NumericalHistogramCardResult extends _CardResult {
        boxPlot?: BoxPlotFragment | null;
        histogram?: NumericalHistogramFragment | null;

        // PolyJson type
        type: 'numerical_histogram';
    }

    export namespace NumericalHistogramCardResult {
        export const type = 'numerical_histogram';
    }

    export function isNumericalHistogramCardResult(toTest: CardResult): toTest is NumericalHistogramCardResult { return NumericalHistogramCardResult.type === toTest.type; }
}
import {ACF} from './timeseries/acf';
import {Chi2IndTest} from './bivariate/chi2-ind-test';
import {CountGroups} from './common/count-groups';
import {Count} from './common/count';
import {Covariance} from './bivariate/covariance';
import {DummyComputation} from './common/dummy-computation';
import {DurbinWatson} from './timeseries/durbin-watson';
import {EmpiricalCDF} from './univariate/empirical-cdf';
import {Entropy} from './univariate/entropy';
import {FetchValues} from './multivariate/fetch-values';
import {Fit2DDistribution} from './bivariate/fit2-ddistribution';
import {FitCurve} from './bivariate/fit-curve';
import {FitDistribution} from './univariate/fit-distribution';
import {GroupedComputation} from './common/grouped-computation';
import {KendallTau} from './bivariate/kendall-tau';
import {KsTest2Samp} from './univariate/ks-test2-samp';
import {Kurtosis} from './univariate/kurtosis';
import {MannKendallTest} from './timeseries/mann-kendall-test';
import {MaxTime} from './univariate/max-time';
import {Max} from './univariate/max';
import {Mean} from './univariate/mean';
import {MinTime} from './univariate/min-time';
import {Min} from './univariate/min';
import {MoodTestNSamp} from './univariate/mood-test-nsamp';
import {MultiComputation} from './common/multi-computation';
import {MutualInformation} from './bivariate/mutual-information';
import {OneWayAnova} from './univariate/one-way-anova';
import {PACF} from './timeseries/pacf';
import {PCA} from './multivariate/pca';
import {PairwiseMoodTest} from './univariate/pairwise-mood-test';
import {PairwiseTTest} from './univariate/pairwise-ttest';
import {Pearson} from './bivariate/pearson';
import {Quantiles} from './univariate/quantiles';
import {Sem} from './univariate/sem';
import {Shapiro} from './univariate/shapiro';
import {SignTest1Samp} from './univariate/sign-test1-samp';
import {Skewness} from './univariate/skewness';
import {Spearman} from './bivariate/spearman';
import {StdDev} from './univariate/std-dev';
import {Sum} from './univariate/sum';
import {TTest1Samp} from './univariate/ttest1-samp';
import {TestDistribution} from './univariate/test-distribution';
import {TimeStep} from './univariate/time-step';
import {UnitRootTestADF} from './timeseries/unit-root-test-adf';
import {UnitRootTestKPSS} from './timeseries/unit-root-test-kpss';
import {UnitRootTestZA} from './timeseries/unit-root-test-za';
import {Variance} from './univariate/variance';
import {ZTest1Samp} from './univariate/ztest1-samp';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.Computation
 */
export interface _Computation {}

export type Computation = CountGroups | Quantiles | EmpiricalCDF | Chi2IndTest | Kurtosis | Spearman | PACF | Covariance | FetchValues | Min | MinTime | PairwiseMoodTest | TimeStep | KsTest2Samp | UnitRootTestKPSS | PCA | Fit2DDistribution | MaxTime | TTest1Samp | Pearson | Sem | UnitRootTestZA | ACF | DummyComputation | MoodTestNSamp | MannKendallTest | FitDistribution | Mean | Sum | Skewness | TestDistribution | DurbinWatson | KendallTau | GroupedComputation | Shapiro | Count | StdDev | MultiComputation | UnitRootTestADF | PairwiseTTest | Max | SignTest1Samp | Entropy | FitCurve | Variance | ZTest1Samp | MutualInformation | OneWayAnova;

export namespace Computation {
    export const type = ["count_groups" , "quantiles" , "empirical_cdf" , "chi2_ind_test" , "kurtosis" , "spearman" , "pacf" , "covariance" , "fetch_values" , "min" , "min_time" , "pairwise_mood_test" , "time_step" , "ks_test_2samp" , "unit_root_kpss" , "pca" , "fit_2d_distribution" , "max_time" , "ttest_1samp" , "pearson" , "sem" , "unit_root_za" , "acf" , "dummy" , "mood_test_nsamp" , "mann_kendall" , "fit_distribution" , "mean" , "sum" , "skewness" , "test_distribution" , "durbin_watson" , "kendall_tau" , "grouped" , "shapiro" , "count" , "std_dev" , "multi" , "unit_root_adf" , "pairwise_ttest" , "max" , "sign_test_1samp" , "entropy" , "fit_curve" , "variance" , "ztest_1samp" , "mutual_information" , "one_way_anova"] as const;
}

export namespace Computation {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.Computation$UnivariateComputation
     */
    export interface _UnivariateComputation extends _Computation {
        column: string;
    }

    export type UnivariateComputation = Quantiles | EmpiricalCDF | Kurtosis | Min | MinTime | PairwiseMoodTest | TimeStep | KsTest2Samp | MaxTime | TTest1Samp | Sem | MoodTestNSamp | FitDistribution | Mean | Skewness | TestDistribution | Sum | Shapiro | StdDev | PairwiseTTest | Max | SignTest1Samp | Entropy | Variance | ZTest1Samp | OneWayAnova;

    export namespace UnivariateComputation {
        export const type = ["quantiles" , "empirical_cdf" , "kurtosis" , "min" , "min_time" , "pairwise_mood_test" , "time_step" , "ks_test_2samp" , "max_time" , "ttest_1samp" , "sem" , "mood_test_nsamp" , "fit_distribution" , "mean" , "skewness" , "test_distribution" , "sum" , "shapiro" , "std_dev" , "pairwise_ttest" , "max" , "sign_test_1samp" , "entropy" , "variance" , "ztest_1samp" , "one_way_anova"] as const;
    }

    export function isUnivariateComputation(toTest: Computation): toTest is UnivariateComputation { return (UnivariateComputation.type as readonly unknown[]).includes(toTest.type); }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.Computation$BivariateComputation
     */
    export interface _BivariateComputation extends _Computation {
        xColumn: string;
        yColumn: string;
    }

    export type BivariateComputation = Pearson | KendallTau | Fit2DDistribution | Spearman | Covariance | FitCurve | MutualInformation;

    export namespace BivariateComputation {
        export const type = ["pearson" , "kendall_tau" , "fit_2d_distribution" , "spearman" , "covariance" , "fit_curve" , "mutual_information"] as const;
    }

    export function isBivariateComputation(toTest: Computation): toTest is BivariateComputation { return (BivariateComputation.type as readonly unknown[]).includes(toTest.type); }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.Computation$MultivariateComputation
     */
    export interface _MultivariateComputation extends _Computation {
        columns: string[];
    }

    export type MultivariateComputation = PCA | FetchValues;

    export namespace MultivariateComputation {
        export const type = ["pca" , "fetch_values"] as const;
    }

    export function isMultivariateComputation(toTest: Computation): toTest is MultivariateComputation { return (MultivariateComputation.type as readonly unknown[]).includes(toTest.type); }
}
import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.MaxTime
 */
export interface MaxTime extends Computation._UnivariateComputation {
    // PolyJson type
    type: 'max_time';
}

export namespace MaxTime {
    export const type = 'max_time';
}

export function isMaxTime(toTest: Computation): toTest is MaxTime { return MaxTime.type === toTest.type; }

export namespace MaxTime {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.MaxTime$MaxTimeResult
     */
    export interface MaxTimeResult extends _AvailableResult {
        timestamp: string;

        // PolyJson type
        type: 'max_time';
    }

    export namespace MaxTimeResult {
        export const type = 'max_time';
    }

    export function isMaxTimeResult(toTest: ComputationResult): toTest is MaxTimeResult { return MaxTimeResult.type === toTest.type; }
}
import {Curve, _Curve} from './curve';

/**
 * Generated from com.dataiku.dip.eda.compute.curves.IsotonicCurve
 */
export interface IsotonicCurve extends _Curve {
    // PolyJson type
    type: 'isotonic';
}

export namespace IsotonicCurve {
    export const type = 'isotonic';
}

export function isIsotonicCurve(toTest: Curve): toTest is IsotonicCurve { return IsotonicCurve.type === toTest.type; }

export namespace IsotonicCurve {
    /**
     * Generated from com.dataiku.dip.eda.compute.curves.IsotonicCurve$ParametrizedIsotonicCurve
     */
    export interface ParametrizedIsotonicCurve extends Curve._ParametrizedCurve {
        // PolyJson type
        type: 'isotonic';
    }

    export namespace ParametrizedIsotonicCurve {
        export const type = 'isotonic';
    }

    export function isParametrizedIsotonicCurve(toTest: Curve.ParametrizedCurve): toTest is ParametrizedIsotonicCurve { return ParametrizedIsotonicCurve.type === toTest.type; }
}
import {Distribution2D, _Distribution2D} from './distribution2-d';

/**
 * Generated from com.dataiku.dip.eda.compute.distributions.JointNormal
 */
export interface JointNormal extends _Distribution2D {
    // PolyJson type
    type: 'joint_normal';
}

export namespace JointNormal {
    export const type = 'joint_normal';
}

export function isJointNormal(toTest: Distribution2D): toTest is JointNormal { return JointNormal.type === toTest.type; }

export namespace JointNormal {
    /**
     * Generated from com.dataiku.dip.eda.compute.distributions.JointNormal$FittedJointNormal
     */
    export interface FittedJointNormal extends Distribution2D._FittedDistribution2D {
        covs: number[][];
        means: number[];

        // PolyJson type
        type: 'joint_normal';
    }

    export namespace FittedJointNormal {
        export const type = 'joint_normal';
    }

    export function isFittedJointNormal(toTest: Distribution2D.FittedDistribution2D): toTest is FittedJointNormal { return FittedJointNormal.type === toTest.type; }
}
import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.Max
 */
export interface Max extends Computation._UnivariateComputation {
    // PolyJson type
    type: 'max';
}

export namespace Max {
    export const type = 'max';
}

export function isMax(toTest: Computation): toTest is Max { return Max.type === toTest.type; }

export namespace Max {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.Max$MaxResult
     */
    export interface MaxResult extends _AvailableResult {
        value: number;

        // PolyJson type
        type: 'max';
    }

    export namespace MaxResult {
        export const type = 'max';
    }

    export function isMaxResult(toTest: ComputationResult): toTest is MaxResult { return MaxResult.type === toTest.type; }
}
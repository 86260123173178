import {BivariateFrequencyTableFragment} from './../fragments/bivariate-frequency-table-fragment';
import {CardResult, _CardResult} from './../card-result';
import {Card} from './../card';
import {_Abstract2DPivotCard} from './abstract2-dpivot-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateFrequencyTableCard
 */
export interface BivariateFrequencyTableCard extends _Abstract2DPivotCard {
    // PolyJson type
    type: 'bivariate_frequency_table';
}

export namespace BivariateFrequencyTableCard {
    export const type = 'bivariate_frequency_table';
}

export function isBivariateFrequencyTableCard(toTest: Card): toTest is BivariateFrequencyTableCard { return BivariateFrequencyTableCard.type === toTest.type; }

export namespace BivariateFrequencyTableCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateFrequencyTableCard$BivariateFrequencyTableCardResult
     */
    export interface BivariateFrequencyTableCardResult extends _CardResult {
        table: BivariateFrequencyTableFragment;

        // PolyJson type
        type: 'bivariate_frequency_table';
    }

    export namespace BivariateFrequencyTableCardResult {
        export const type = 'bivariate_frequency_table';
    }

    export function isBivariateFrequencyTableCardResult(toTest: CardResult): toTest is BivariateFrequencyTableCardResult { return BivariateFrequencyTableCardResult.type === toTest.type; }
}
import {ComputationResult} from './computation-result';
import {EdaErrorCodes} from './../../eda-error-codes';
import {_UnavailableResult} from './unavailable-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.FailedResult
 */
export interface FailedResult extends _UnavailableResult {
    code: EdaErrorCodes;
    message: string;

    // PolyJson type
    type: 'failed';
}

export namespace FailedResult {
    export const type = 'failed';
}

export function isFailedResult(toTest: ComputationResult): toTest is FailedResult { return FailedResult.type === toTest.type; }
import {Card, _Card} from './card';
import {CorrelationMatrixCard} from './correlation-matrix-card';
import {PCACard} from './pcacard';
import {ParallelCoordinatesPlotCard} from './parallel-coordinates-plot-card';
import {Variable} from './../models/variable';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.MultivariateCard
 */
export interface _MultivariateCard extends _Card {
    columns: Variable[];
}

export type MultivariateCard = ParallelCoordinatesPlotCard | PCACard | CorrelationMatrixCard;

export namespace MultivariateCard {
    export const type = ["parallel_coordinates_plot" , "pca" , "correlation_matrix"] as const;
}

export function isMultivariateCard(toTest: Card): toTest is MultivariateCard { return (MultivariateCard.type as readonly unknown[]).includes(toTest.type); }
import {CardResult, _CardResult} from './../card-result';
import {Card} from './../card';
import {Filter} from './../../../compute/filtering/filter';
import {PolynomialCurve} from './../../../compute/curves/polynomial-curve';
import {SplitBySpec} from './../../models/split-by-spec';
import {_BivariateCard} from './../bivariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.bivariate_header.ScatterPlotCard
 */
export interface ScatterPlotCard extends _BivariateCard {
    colorBy?: SplitBySpec | null;
    highlightFilter?: Filter | null;
    maxNumberOfPoints: number;
    showTrend: boolean;
    symbolSize: number;

    // PolyJson type
    type: 'scatter_plot';
}

export namespace ScatterPlotCard {
    export const type = 'scatter_plot';
}

export function isScatterPlotCard(toTest: Card): toTest is ScatterPlotCard { return ScatterPlotCard.type === toTest.type; }

export namespace ScatterPlotCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.bivariate_header.ScatterPlotCard$ScatterPlotSeries
     */
    export interface ScatterPlotSeries {
        groupIndex: number;
        isHighlighted: boolean;
        trend?: PolynomialCurve.ParametrizedPolynomialCurve | null;
        xSeries: number[];
        ySeries: number[];
    }

    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.bivariate_header.ScatterPlotCard$ScatterPlotCardResult
     */
    export interface ScatterPlotCardResult extends _CardResult {
        groups: Filter[];
        series: ScatterPlotCard.ScatterPlotSeries[];

        // PolyJson type
        type: 'scatter_plot';
    }

    export namespace ScatterPlotCardResult {
        export const type = 'scatter_plot';
    }

    export function isScatterPlotCardResult(toTest: CardResult): toTest is ScatterPlotCardResult { return ScatterPlotCardResult.type === toTest.type; }
}
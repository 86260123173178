<div class="simple-columns mbot8">
    <h2 class="paned-page-section__subheading">Prediction</h2>
    <form class="dkuform-horizontal" [formGroup]="form">
        <div class="control-group">
            <div class="controls mleft0">
                <dku-bs-select-form-control
                    formControlName="selectedCategories"
                    ngOptions="category for category in list"
                    [list]="categories"
                    dataActionsBox="true"
                    dataLiveSearch="true"
                    dkuMultiple="multiple"
                    [layout]="'list'"
                    [params]="{
                        noneSelectedText: 'Filter categories...'
                    }">
                </dku-bs-select-form-control>
            </div>
        </div>
    </form>
</div>

<ng-container *ngIf="matrix$ | async as matrix">
    <confusion-matrix
        [data]="matrix.data"
        [xHeaderLabel]="'Predicted'"
        [yHeaderLabel]="'Ground Truth'"
        [xLabels]="matrix.xCategories"
        [yLabels]="matrix.yCategories"
        [filteredLabelMap]="filteredCategoryMap$ | async"
        [readOnly]="true"
        [heatmapParams]="matrix.params"
        [popoverTemplate]="popoverTemplate"
        (cellClicked)="matrixClick($event)"
    >
    </confusion-matrix>
</ng-container>

<ng-template #popoverTemplate let-detectionLabel="xLabel" let-groundTruthLabel="yLabel" let-value="value">
    <ul class="raw-unstyled-ul">
        <li>Actual <strong>{{ groundTruthLabel}}</strong></li>
        <li>Predicted <strong>{{ detectionLabel }}</strong></li>
        <li><strong>{{ value }}</strong> {{ (detectionLabel === NO_DETECTION_LABEL ? 'object' : 'detection') | plurify: value }}</li>
    </ul>
    <div class="tac text-prompt mtop8">
        Click cell to show objects
    </div>
</ng-template>
import {ComputationResult, _ComputationResult} from './computation-result';
import {FailedResult} from './failed-result';
import {NotComputedResult} from './not-computed-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.UnavailableResult
 */
export interface _UnavailableResult extends _ComputationResult {}

export type UnavailableResult = FailedResult | NotComputedResult;

export namespace UnavailableResult {
    export const type = ["failed" , "not_computed"] as const;
}

export function isUnavailableResult(toTest: ComputationResult): toTest is UnavailableResult { return (UnavailableResult.type as readonly unknown[]).includes(toTest.type); }
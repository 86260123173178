<feed-image-modal [imageId]="data.imageId" [columns]="data.columns">
    <ng-template let-image="image">
        <image-metadata class="padleftright24 mbot16 db" [image]="image"></image-metadata>
        
        <div *ngIf="formattedPredictions$ | async as formattedPredictions">
            
            <div class="padleftright24 mbot32 db">
                <div class="deephub-image-classification-feed-modal__results-header">Ground Truth</div>
                <div class="mtop8 deephub-image-classification-feed-modal__category"
                          [style.background-color]="getCategoryColor(image?.cellData.target)">{{image?.cellData.target}}
                </div>
                <div class="deephub-image-classification-feed-modal__results-header">Prediction</div>
                <div class="deephub-image-classification-feed-modal__results-horizontal-bars" >
                    <div
                        *ngFor="let p of formattedPredictions"
                        [style.background-color]="p.color"
                        [style.width.%]="p.proba * 100"
                        [style.height.px]="16"
                        [matTooltip]="p.title" matTooltipPosition="below">
                    </div>
                </div>

                <div class="deephub-image-classification-feed-modal__results-horizontal-bars deephub-image-classification-feed-modal__results-horizontal-axis">
                    <div>0</div>
                    <div>20</div>
                    <div>40</div>
                    <div>60</div>
                    <div>80</div>
                    <div>100%</div>
                </div>
            </div>

            
            <div class="deephub-image-classification-feed-modal__row"
                *ngFor="let p of formattedPredictions">

                <div class="mbot4 deephub-image-classification-feed-modal__category"
                    [style.background-color]="p.category === image?.cellData?.prediction? p.color : ''">
                    <i  *ngIf="p.category === image?.cellData?.prediction"
                        [ngClass]="{'icon-ok-sign': image?.cellData?.target === p.category,
                                   'icon-remove': image?.cellData?.target !== p.category}"
                        class="deephub-image-classification-feed-modal__category-elt"></i>
                    <span *ngIf="p.category !== image?.cellData?.prediction"
                          class="deephub-image-classification-feed-modal__category-elt deephub-image-classification-feed-modal__row-bullet"
                          [style.color]="p.color"></span>

                    <span *ngIf="!p.others" class="mleft4 deephub-image-classification-feed-modal__category-elt">{{p.category}} </span>
                    <span *ngIf="p.others" class="mleft4 deephub-image-classification-feed-modal__category-elt" [matTooltip]="p.title" matTooltipPosition="below"><em>Others</em></span>
                </div>

                <div class="mbot4">{{(p.proba *100).toFixed(1)}}%</div>
            </div>
            
        </div>
    </ng-template>
</feed-image-modal>

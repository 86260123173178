import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EChartsOption } from 'echarts';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { DeepHubPredictionModelPerf } from 'src/generated-sources';
import { DeephubObjectDetectionReportService } from '../services/deephub-object-detection-report.service';

@UntilDestroy()
@Component({
    selector: 'deephub-object-detection-report-precision-recall-chart',
    templateUrl: './deephub-object-detection-report-precision-recall-chart.component.html',
    styleUrls: ['./deephub-object-detection-report-precision-recall-chart.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeephubObjectDetectionReportPrecisionRecallChartComponent implements OnInit {
    chartOptions$: Observable<EChartsOption>;

    constructor(
        private objectDetectionService: DeephubObjectDetectionReportService
    ) { }

    ngOnInit(): void {
        this.chartOptions$ = this.objectDetectionService.getReport()
            .pipe(
                debounceTime(400),
                distinctUntilChanged((prev, curr) => prev.iou === curr.iou),
                map(report => this.buildChartOptions(report.perf, report.iou))
            )
    }

    buildChartOptions(data: DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf, iou: number): EChartsOption {
        const perIOU = data.perIOU.find(data => data.iou === iou);
        const curveData = perIOU!.precisionRecallCurve.curve;

        return {
            tooltip: {
                trigger: 'item',
                formatter: (param: any) => {
                    return `
                        <strong>Recall</strong>: ${curveData.recall[param.dataIndex].toFixed(4)}<br />
                        <strong>Precision</strong>: ${curveData.precision[param.dataIndex].toFixed(4)}<br />
                        <strong>F1</strong>: ${curveData.f1[param.dataIndex].toFixed(4)}<br />
                        <strong>Confidence</strong>: ${curveData.confidence[param.dataIndex].toFixed(4)}
                    `;
                }
            },
            grid: { left: 30, top: 16, right: 16, bottom: 30, containLabel: true },
            xAxis: [
                {
                    type: 'value',
                    name: 'Recall',
                    nameLocation: 'middle',
                    nameGap: 30,
                    axisLabel: {
                        formatter
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: 'Precision',
                    nameLocation: 'middle',
                    nameGap: 40,
                    axisLabel: {
                        formatter
                    }
                }
            ],
            series: [
                {
                    type: 'line',
                    showSymbol: true,
                    data: curveData.recall.map((recall, index) => [recall, curveData.precision[index]])
                }
            ]
        };

        function formatter(value: number) {
            return value * 100 + '%';
        }
    }
}

import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';

export abstract class WT1Service {
    abstract event(type: string, params: { [index: string]: any }): void;
    abstract configure(args: {
        dipInstanceId?: string;
        licenseKind?: string;
        distrib?: string;
        distribVersion?: string;
        registrationChannel?: string;
        isAutomation?: string;
        version?: string;
        login?: string;
        udr?: boolean;
    }): void;
}

@Injectable()
export class StandaloneWT1Service extends WT1Service {
    private configured = false;
    private enabled = true;
    private dlid: string;
    private unconfiguredEvents: any[] = [];
    private wt1Queue: any[];

    private dkuHashCode(value: string): number {
        let hash = 0;
        let char: number;
        if (value.length === 0) {
            return hash;
        }
        for (let i = 0; i < value.length; i++) {
            char = value.charCodeAt(i);
            // tslint:disable-next-line: no-bitwise
            hash = ((hash << 5) - hash) + char;
            // tslint:disable-next-line: no-bitwise
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }

    configure(args: {
        dipInstanceId?: string;
        licenseKind?: string;
        distrib?: string;
        distribVersion?: string;
        registrationChannel?: string;
        isAutomation?: string;
        version?: string;
        login?: string;
        udr?: boolean;
    }): void {
        this.dlid = (Math.random().toString(36) + '00000000000000000').slice(2, 12);
        this.wt1Queue = (window as any)._wt1Q = [];
        if (args.dipInstanceId) {
            this.wt1Queue.push(['setVisitorParam', 'dipInstanceId', args.dipInstanceId]);
        }
        if (args.licenseKind) {
            this.wt1Queue.push(['setVisitorParam', 'dssLicenseKind', args.licenseKind]);
        }
        if (args.distrib) {
            this.wt1Queue.push(['setVisitorParam', 'bkdDistrib', args.distrib]);
        }
        if (args.distribVersion) {
            this.wt1Queue.push(['setVisitorParam', 'bkdDistribVersion', args.distribVersion]);
        }
        if (args.registrationChannel) {
            this.wt1Queue.push(['setVisitorParam', 'regChannel', args.registrationChannel]);
        }
        this.wt1Queue.push(['setVisitorParam', 'isAutomation', args.isAutomation]);
        this.wt1Queue.push(['setVisitorParam', 'dssVersion', args.version || 'unknown']);
        if (args.login) {
            this.wt1Queue.push(['setSessionParam', 'dssUser', this.dkuHashCode(args.login)]);
            this.wt1Queue.push(['setVisitorParam', 'vdssUser', this.dkuHashCode(args.login)]);
        }
        if (!args.udr || (window as any).devInstance) {
            this.enabled = false;
        }
        this.configured = true;
        if (this.enabled) {
            this.unconfiguredEvents.forEach(event => this.wt1Queue.push(event));
            // Load the JS library that will read _wt1Q array
            (() => {
                const script = document.createElement('script');
                script.src = '//tracker.dataiku.com/js/track.js';
                script.type = 'text/javascript';
                script.async = true;
                document.head.appendChild(script);
            })();
        }
    }

    event(type: string, params: { [index: string]: any } = {}): void {
        // When params contains object litterals, class instances we must
        // serialize them before sending them to the event queue.
        for (let key in params) {
            if (params.hasOwnProperty(key)) {
                const value = params[key];

                if (Object.prototype.toString.call(value) === '[object Object]') {
                    params[key] = JSON.stringify(value);
                }
            }
        }

        if ((window as any).devInstance) {
            const formattedDate = formatDate(new Date(), 'HH:mm:ss.SSS', 'en-US', 'UTC');
            // tslint:disable-next-line: no-console
            console.debug('[' + formattedDate + '] WT1: ' + type, params);
        }
        if (!this.enabled) {
            return;
        }
        params.type = type;
        params.dlid = this.dlid;

        if (!this.configured) {
            // store the event while the service is not configured
            this.unconfiguredEvents.push(['trackEvent', params]);
        } else {
            // otherwise push the event in the queue, the JS lib will handle it
            this.wt1Queue.push(['trackEvent', params]);
        }
    }
}

@Injectable()
export class LegacyWT1Service extends WT1Service {
    configure(args: {
        dipInstanceId?: string;
        licenseKind?: string;
        distrib?: string;
        distribVersion?: string;
        registrationChannel?: string;
        isAutomation?: string;
        version?: string;
        login?: string;
        udr?: boolean;
    }): void {
        console.warn('Cannot configure WT1 service when configured to use the AngularJS service');
    }

    event(type: string, params: { [index: string]: any } = {}): void {
        // Retrive the WT1 AngularJS service referenced in window (no configuration needed)
        const wt1Svc = (window as any).WT1SVC;
        if (!wt1Svc) {
            console.warn('WT1 AngularJS service is not found');
        } else {
            wt1Svc.event(type, params);
        }
    }
}

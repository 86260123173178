import {CardResult} from './card-result';
import {Card} from './card';
import {TimeSeriesCard, _TimeSeriesCard} from './time-series-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.DurbinWatsonCard
 */
export interface DurbinWatsonCard extends _TimeSeriesCard {
    // PolyJson type
    type: 'durbin_watson';
}

export namespace DurbinWatsonCard {
    export const type = 'durbin_watson';
}

export function isDurbinWatsonCard(toTest: Card): toTest is DurbinWatsonCard { return DurbinWatsonCard.type === toTest.type; }

export namespace DurbinWatsonCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.DurbinWatsonCard$DurbinWatsonCardResult
     */
    export interface DurbinWatsonCardResult extends TimeSeriesCard._TimeSeriesCardResult {
        statistic: number;

        // PolyJson type
        type: 'durbin_watson';
    }

    export namespace DurbinWatsonCardResult {
        export const type = 'durbin_watson';
    }

    export function isDurbinWatsonCardResult(toTest: CardResult): toTest is DurbinWatsonCardResult { return DurbinWatsonCardResult.type === toTest.type; }
}
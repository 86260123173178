import {Filter, _Filter} from './filter';

/**
 * Generated from com.dataiku.dip.eda.compute.filtering.MissingValueFilter
 */
export interface MissingValueFilter extends _Filter {
    column: string;

    // PolyJson type
    type: 'missing';
}

export namespace MissingValueFilter {
    export const type = 'missing';
}

export function isMissingValueFilter(toTest: Filter): toTest is MissingValueFilter { return MissingValueFilter.type === toTest.type; }
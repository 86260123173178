import {ACFPlotCard} from './acfplot-card';
import {BoxPlotFragment} from './fragments/box-plot-fragment';
import {Card, _Card} from './card';
import {CardResult, _CardResult} from './card-result';
import {DurbinWatsonCard} from './durbin-watson-card';
import {MannKendallTestCard} from './mann-kendall-test-card';
import {TimeInfoFragment} from './fragments/time-info-fragment';
import {UnitRootTestADFCard} from './unit-root-test-adfcard';
import {UnitRootTestKPSSCard} from './unit-root-test-kpsscard';
import {UnitRootTestZACard} from './unit-root-test-zacard';
import {Variable} from './../models/variable';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.TimeSeriesCard
 */
export interface _TimeSeriesCard extends _Card {
    seriesColumn: Variable;
    seriesIdentifiers: TimeSeriesCard.TimeSeriesIdentifier[];
    timeColumn: Variable;
}

export type TimeSeriesCard = UnitRootTestADFCard | UnitRootTestKPSSCard | DurbinWatsonCard | UnitRootTestZACard | ACFPlotCard | MannKendallTestCard;

export namespace TimeSeriesCard {
    export const type = ["unit_root_test_adf" , "unit_root_test_kpss" , "durbin_watson" , "unit_root_test_za" , "acf_plot" , "mann_kendall_test"] as const;
}

export function isTimeSeriesCard(toTest: Card): toTest is TimeSeriesCard { return (TimeSeriesCard.type as readonly unknown[]).includes(toTest.type); }

export namespace TimeSeriesCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.TimeSeriesCard$TimeSeriesCardResult
     */
    export interface _TimeSeriesCardResult extends _CardResult {
        boxPlot: BoxPlotFragment;
        timeInfo: TimeInfoFragment;
    }

    export type TimeSeriesCardResult = UnitRootTestADFCard.UnitRootTestADFCardResult | MannKendallTestCard.MannKendallTestCardResult | ACFPlotCard.ACFPlotCardResult | DurbinWatsonCard.DurbinWatsonCardResult | UnitRootTestKPSSCard.UnitRootTestKPSSCardResult | UnitRootTestZACard.UnitRootTestZACardResult;

    export namespace TimeSeriesCardResult {
        export const type = ["unit_root_test_adf" , "mann_kendall_test" , "acf_plot" , "durbin_watson" , "unit_root_test_kpss" , "unit_root_test_za"] as const;
    }

    export function isTimeSeriesCardResult(toTest: CardResult): toTest is TimeSeriesCardResult { return (TimeSeriesCardResult.type as readonly unknown[]).includes(toTest.type); }

    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.TimeSeriesCard$TimeSeriesIdentifier
     */
    export interface TimeSeriesIdentifier {
        column: Variable;
        value: string;
    }
}
import {CardResult} from './card-result';
import {Card} from './card';
import {TimeSeriesCard, _TimeSeriesCard} from './time-series-card';
import {UnitRootTestADFCard} from './unit-root-test-adfcard';
import {UnitRootTestKPSSCard} from './unit-root-test-kpsscard';
import {UnitRootTestZACard} from './unit-root-test-zacard';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.UnitRootTestCard
 */
export interface _UnitRootTestCard extends _TimeSeriesCard {
    confidenceLevel: number;
    nLags?: number | null;
}

export type UnitRootTestCard = UnitRootTestADFCard | UnitRootTestKPSSCard | UnitRootTestZACard;

export namespace UnitRootTestCard {
    export const type = ["unit_root_test_adf" , "unit_root_test_kpss" , "unit_root_test_za"] as const;
}

export function isUnitRootTestCard(toTest: Card): toTest is UnitRootTestCard { return (UnitRootTestCard.type as readonly unknown[]).includes(toTest.type); }

export namespace UnitRootTestCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.UnitRootTestCard$UnitRootTestCardResult
     */
    export interface _UnitRootTestCardResult extends TimeSeriesCard._TimeSeriesCardResult {
        pValue: number;
        statistic: number;
        usedLag: number;
    }

    export type UnitRootTestCardResult = UnitRootTestADFCard.UnitRootTestADFCardResult | UnitRootTestKPSSCard.UnitRootTestKPSSCardResult | UnitRootTestZACard.UnitRootTestZACardResult;

    export namespace UnitRootTestCardResult {
        export const type = ["unit_root_test_adf" , "unit_root_test_kpss" , "unit_root_test_za"] as const;
    }

    export function isUnitRootTestCardResult(toTest: CardResult): toTest is UnitRootTestCardResult { return (UnitRootTestCardResult.type as readonly unknown[]).includes(toTest.type); }
}
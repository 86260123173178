<ng-container *ngIf="displayedResults && displayedParams">
    <div
        class="h100"
        [qa]="{'eda-card-body': {'id': params.id,'type': params.type}}"
        *ngIf="!displayOverlay && displayedResults.type == 'unavailable'; else availableBody"
    >
        <unavailable-card-body [results]="displayedResults" [params]="displayedParams"></unavailable-card-body>
    </div>
    <ng-template #availableBody>
        <ng-container *ngIf="displayedParams.type == displayedResults.type" [ngSwitch]="displayedParams.type">
            <div [qa]="{'eda-card-body': {id: params.id, type: params.type}}" class="h100">
                <categorical-histogram-card-body *ngSwitchCase="'categorical_histogram'" class="header-stat-card"
                    [params]="displayedParams" [results]="displayedResults" [hasFixedHeight]="hasFixedHeight"
                    (action)="propagateAction($event)" [renderingMode]="renderingMode">
                </categorical-histogram-card-body>

                <ks-test-2samp-card-body *ngSwitchCase="'ks_test_2samp'" [params]="displayedParams"
                    [results]="displayedResults" (action)="propagateAction($event)">
                </ks-test-2samp-card-body>

                <generic-nsamp-test-card-body *ngSwitchCase="'mood_nsamp'" [params]="displayedParams"
                    [results]="displayedResults" (action)="propagateAction($event)">
                </generic-nsamp-test-card-body>

                <tztest1-samp-card-body *ngSwitchCase="'ttest_ztest_1samp'" [params]="displayedParams"
                    [results]="displayedResults" (action)="propagateAction($event)">
                </tztest1-samp-card-body>

                <sign-test1-samp-card-body *ngSwitchCase="'sign_test_1samp'" [params]="displayedParams"
                    [results]="displayedResults" (action)="propagateAction($event)">
                </sign-test1-samp-card-body>

                <generic-nsamp-test-card-body *ngSwitchCase="'ttest_2samp'" [params]="displayedParams"
                    [results]="displayedResults" (action)="propagateAction($event)">
                </generic-nsamp-test-card-body>

                <generic-nsamp-test-card-body *ngSwitchCase="'mood_test_2samp'" [params]="displayedParams"
                    [results]="displayedResults" (action)="propagateAction($event)">
                </generic-nsamp-test-card-body>

                <generic-nsamp-test-card-body *ngSwitchCase="'oneway_anova'" [params]="displayedParams"
                    [results]="displayedResults" (action)="propagateAction($event)">
                </generic-nsamp-test-card-body>

                <generic-pairwise-test-card-body *ngSwitchCase="'pairwise_ttest'" [params]="displayedParams"
                    [results]="displayedResults" (action)="propagateAction($event)">
                </generic-pairwise-test-card-body>

                <generic-pairwise-test-card-body *ngSwitchCase="'pairwise_mood'" [params]="displayedParams"
                    [results]="displayedResults" (action)="propagateAction($event)">
                </generic-pairwise-test-card-body>

                <numerical-histogram-card-body *ngSwitchCase="'numerical_histogram'" class="header-stat-card"
                    [params]="displayedParams" [results]="displayedResults" [hasFixedHeight]="hasFixedHeight"
                    (action)="propagateAction($event)" [renderingMode]="renderingMode">
                </numerical-histogram-card-body>

                <generic-summary-stats-card-body *ngSwitchCase="'univariate_summary'" class="header-stat-card"
                    [params]="displayedParams" [results]="displayedResults" (action)="propagateAction($event)"
                    [readOnly]="readOnly" [extendedActions]="extendedActions">
                </generic-summary-stats-card-body>

                <shapiro-normality-test-card-body *ngSwitchCase="'shapiro'" [params]="displayedParams"
                    [results]="displayedResults" (action)="propagateAction($event)">
                </shapiro-normality-test-card-body>

                <quantiles-table-card-body *ngSwitchCase="'quantile_table'" class="header-stat-card"
                    [params]="displayedParams" [results]="displayedResults" (action)="propagateAction($event)">
                </quantiles-table-card-body>

                <univariate-frequency-table-card-body *ngSwitchCase="'univariate_frequency_table'" class="header-stat-card"
                    [params]="displayedParams" [results]="displayedResults" (action)="propagateAction($event)">
                </univariate-frequency-table-card-body>

                <bivariate-histogram-card-body *ngSwitchCase="'bivariate_histogram'" class="header-stat-card"
                    [params]="displayedParams" [results]="displayedResults" [hasFixedHeight]="hasFixedHeight"
                    (action)="propagateAction($event)">
                </bivariate-histogram-card-body>

                <bivariate-box-plot-card-body *ngSwitchCase="'bivariate_box_plot'" class="header-stat-card"
                    [params]="displayedParams" [results]="displayedResults" [hasFixedHeight]="hasFixedHeight"
                    (action)="propagateAction($event)">
                </bivariate-box-plot-card-body>

                <scatter-plot-card-body *ngSwitchCase="'scatter_plot'" class="header-stat-card" [params]="displayedParams"
                    [results]="displayedResults" [hasFixedHeight]="hasFixedHeight" (action)="propagateAction($event)">
                </scatter-plot-card-body>

                <mosaic-plot-card-body *ngSwitchCase="'mosaic_plot'" class="header-stat-card" [params]="displayedParams"
                    [results]="displayedResults" [hasFixedHeight]="hasFixedHeight" (action)="propagateAction($event)">
                </mosaic-plot-card-body>

                <generic-summary-stats-card-body *ngSwitchCase="'bivariate_summary'" class="header-stat-card"
                    [params]="displayedParams" [results]="displayedResults" (action)="propagateAction($event)"
                    [readOnly]="readOnly" [extendedActions]="extendedActions">
                </generic-summary-stats-card-body>

                <bivariate-frequency-table-card-body *ngSwitchCase="'bivariate_frequency_table'" class="header-stat-card"
                    [params]="displayedParams" [results]="displayedResults" [renderingMode]="renderingMode"
                    (action)="propagateAction($event)">
                </bivariate-frequency-table-card-body>

                <fit-2d-distribution-card-body *ngSwitchCase="'fit_2d_distribution'" [params]="displayedParams"
                    [results]="displayedResults" [hasFixedHeight]="hasFixedHeight" (action)="propagateAction($event)">
                </fit-2d-distribution-card-body>

                <header-card-body *ngSwitchCase="'univariate_header'" [params]="displayedParams"
                    [results]="displayedResults" [readOnly]="readOnly" [extendedActions]="extendedActions" (action)="propagateAction($event)">
                </header-card-body>

                <header-card-body *ngSwitchCase="'bivariate_header'" [params]="displayedParams" [results]="displayedResults"
                    [readOnly]="readOnly" [extendedActions]="extendedActions" (action)="propagateAction($event)">
                </header-card-body>

                <fit-distribution-card-body *ngSwitchCase="'fit_distribution'" [params]="displayedParams"
                    [results]="displayedResults" (action)="propagateAction($event)">
                </fit-distribution-card-body>

                <chi2-ind-test-card-body *ngSwitchCase="'chi2_independence_test'" [params]="displayedParams"
                    [results]="displayedResults" (action)="propagateAction($event)">
                </chi2-ind-test-card-body>

                <fit-curve-card-body *ngSwitchCase="'fit_curve'" [params]="displayedParams" [results]="displayedResults"
                    (action)="propagateAction($event)">
                </fit-curve-card-body>

                <correlation-matrix-card-body *ngSwitchCase="'correlation_matrix'" [params]="displayedParams"
                    [results]="displayedResults" [readOnly]="readOnly" (action)="propagateAction($event)">
                </correlation-matrix-card-body>

                <scatter-plot-3d-card-body *ngSwitchCase="'scatter_plot_3d'" [params]="displayedParams"
                    [results]="displayedResults" (action)="propagateAction($event)">
                </scatter-plot-3d-card-body>

                <cdf-plot-card-body *ngSwitchCase="'cdf_plot'" class="header-stat-card"
                    [hasFixedHeight]="hasFixedHeight" [params]="displayedParams"
                    [results]="displayedResults" (action)="propagateAction($event)"
                    [renderingMode]="renderingMode">
                </cdf-plot-card-body>

                <parallel-coordinates-plot-card-body *ngSwitchCase="'parallel_coordinates_plot'"
                    [params]="displayedParams"
                    [results]="displayedResults"
                    (action)="propagateAction($event)">
                </parallel-coordinates-plot-card-body>

                <pca-card-body *ngSwitchCase="'pca'" [params]="displayedParams" [results]="displayedResults"
                    [hasFixedHeight]="hasFixedHeight" [readOnly]="readOnly" (action)="propagateAction($event)">
                </pca-card-body>

                <unit-root-test-card-body
                    *ngSwitchCase="'unit_root_test_adf'"
                    [params]="displayedParams"
                    [results]="displayedResults"
                ></unit-root-test-card-body>

                <unit-root-test-card-body
                    *ngSwitchCase="'unit_root_test_za'"
                    [params]="displayedParams"
                    [results]="displayedResults"
                ></unit-root-test-card-body>

                <unit-root-test-card-body
                    *ngSwitchCase="'unit_root_test_kpss'"
                    [params]="displayedParams"
                    [results]="displayedResults"
                ></unit-root-test-card-body>

                <durbin-watson-card-body
                    *ngSwitchCase="'durbin_watson'"
                    [params]="displayedParams"
                    [results]="displayedResults"
                >
                </durbin-watson-card-body>

                <mann-kendall-test-card-body
                    *ngSwitchCase="'mann_kendall_test'"
                    [params]="displayedParams"
                    [results]="displayedResults"
                ></mann-kendall-test-card-body>

                <acf-plot-card-body
                    *ngSwitchCase="'acf_plot'"
                    [params]="displayedParams"
                    [results]="displayedResults"
                    [hasFixedHeight]="hasFixedHeight"
                ></acf-plot-card-body>

                <playground-card-body
                    *ngSwitchCase="'playground'"
                    [params]="displayedParams"
                    [results]="displayedResults"
                    [readOnly]="readOnly"
                    (action)="propagateAction($event)"
                ></playground-card-body>

                <ng-container *ngSwitchDefault>
                    NOT IMPLEMENTED: {{ displayedParams.type }}
                </ng-container>
            </div>
        </ng-container>

        <ng-container *ngIf="displayedResults.type == 'groups'">
            <ng-container [ngSwitch]="displayedParams.type">
                <!-- Card has a custom/special grouped layout -->
                <ng-container *ngSwitchCase="'univariate_header'">
                    <grouped-header-card-body [params]="displayedParams" [results]="displayedResults" [readOnly]="readOnly" [extendedActions]="extendedActions" (action)="propagateAction($event)">
                    </grouped-header-card-body>
                </ng-container>

                <ng-container *ngSwitchCase="'bivariate_header'">
                    <grouped-header-card-body [params]="displayedParams" [results]="displayedResults" [readOnly]="readOnly" [extendedActions]="extendedActions" (action)="propagateAction($event)">
                    </grouped-header-card-body>
                </ng-container>

                <!-- Most cards are using the generic grouped layout -->
                <grouped-card-body *ngSwitchDefault [params]="displayedParams" [results]="displayedResults"
                    [renderingMode]="CardBodyRenderingMode.TOP_LEVEL_CARD" [readOnly]="readOnly" [extendedActions]="extendedActions"[hasFixedHeight]="hasFixedHeight" (action)="propagateAction($event)"></grouped-card-body>
            </ng-container>
        </ng-container>

        <div *ngFor="let warning of distinctWarnings; trackBy: trackByIndex" class="alert alert-warning">
            <i class="icon-warning-sign"></i>
            {{ warning }}
        </div>
    </ng-template>
    <div class="overlay" *ngIf="displayOverlay">
        <div>
            <span class="dku-loader icon-spin card-spinner"></span>
        </div>
    </div>
</ng-container>

import { ImagePositionInformation, PainterImageArtifact } from "@shared/models/painter";

export class ImageClassificationLabelHelper {
    static createLabel(imagePosition: ImagePositionInformation, color?: string) {
        return new PainterImageArtifact({
            backgroundColor: color,
            left: "4px",
            top: "calc(100% - 24px)",
            color: "black",
            padding: "0px 4px",
            borderRadius: "15px",
        });
    }
}

import {Filter, _Filter} from './filter';

/**
 * Generated from com.dataiku.dip.eda.compute.filtering.AndFilter
 */
export interface AndFilter extends _Filter {
    filters: Filter[];

    // PolyJson type
    type: 'and';
}

export namespace AndFilter {
    export const type = 'and';
}

export function isAndFilter(toTest: Filter): toTest is AndFilter { return AndFilter.type === toTest.type; }
import {AnumGrouping} from './anum-grouping';
import {BinnedGrouping} from './binned-grouping';
import {CrossGrouping} from './cross-grouping';
import {MergeGrouping} from './merge-grouping';
import {SubsampledGrouping} from './subsampled-grouping';
import {SubsetGrouping} from './subset-grouping';
import {TopNTimeGrouping} from './top-ntime-grouping';
import {UnionGrouping} from './union-grouping';

/**
 * Generated from com.dataiku.dip.eda.compute.grouping.Grouping
 */
export interface _Grouping {}

export type Grouping = MergeGrouping | TopNTimeGrouping | CrossGrouping | AnumGrouping | SubsetGrouping | UnionGrouping | SubsampledGrouping | BinnedGrouping;

export namespace Grouping {
    export const type = ["merge" , "topn_time" , "cross" , "anum" , "subset" , "union" , "subsampled" , "binned"] as const;
}
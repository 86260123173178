import {CardResult, _CardResult} from './card-result';
import {Card} from './card';
import {Filter} from './../../compute/filtering/filter';
import {SplitBySpec} from './../models/split-by-spec';
import {_UnivariateCard} from './univariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.CDFPlotCard
 */
export interface CDFPlotCard extends _UnivariateCard {
    colorBy?: SplitBySpec | null;
    highlightFilter?: Filter | null;

    // PolyJson type
    type: 'cdf_plot';
}

export namespace CDFPlotCard {
    export const type = 'cdf_plot';
}

export function isCDFPlotCard(toTest: Card): toTest is CDFPlotCard { return CDFPlotCard.type === toTest.type; }

export namespace CDFPlotCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.CDFPlotCard$CDFItem
     */
    export interface CDFItem {
        groupIndex: number;
        isHighlighted: boolean;
        probs: number[];
        xvals: number[];
    }

    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.CDFPlotCard$CDFPlotCardResult
     */
    export interface CDFPlotCardResult extends _CardResult {
        cdfs: CDFPlotCard.CDFItem[];
        groups: Filter[];

        // PolyJson type
        type: 'cdf_plot';
    }

    export namespace CDFPlotCardResult {
        export const type = 'cdf_plot';
    }

    export function isCDFPlotCardResult(toTest: CardResult): toTest is CDFPlotCardResult { return CDFPlotCardResult.type === toTest.type; }
}
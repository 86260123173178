import {Computation, _Computation} from './../computation';
import {ComputationResult} from './../computation-result';
import {Grouping} from './../../grouping/grouping';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.common.CountGroups
 */
export interface CountGroups extends _Computation {
    grouping: Grouping;

    // PolyJson type
    type: 'count_groups';
}

export namespace CountGroups {
    export const type = 'count_groups';
}

export function isCountGroups(toTest: Computation): toTest is CountGroups { return CountGroups.type === toTest.type; }

export namespace CountGroups {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.common.CountGroups$CountGroupsResult
     */
    export interface CountGroupsResult extends _AvailableResult {
        count: number;

        // PolyJson type
        type: 'count_groups';
    }

    export namespace CountGroupsResult {
        export const type = 'count_groups';
    }

    export function isCountGroupsResult(toTest: ComputationResult): toTest is CountGroupsResult { return CountGroupsResult.type === toTest.type; }
}
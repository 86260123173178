import {Filter, _Filter} from './filter';

/**
 * Generated from com.dataiku.dip.eda.compute.filtering.NotFilter
 */
export interface NotFilter extends _Filter {
    filter: Filter;

    // PolyJson type
    type: 'not';
}

export namespace NotFilter {
    export const type = 'not';
}

export function isNotFilter(toTest: Filter): toTest is NotFilter { return NotFilter.type === toTest.type; }
import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.bivariate.KendallTau
 */
export interface KendallTau extends Computation._BivariateComputation {
    // PolyJson type
    type: 'kendall_tau';
}

export namespace KendallTau {
    export const type = 'kendall_tau';
}

export function isKendallTau(toTest: Computation): toTest is KendallTau { return KendallTau.type === toTest.type; }

export namespace KendallTau {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.bivariate.KendallTau$KendallTauResult
     */
    export interface KendallTauResult extends _AvailableResult {
        correlation: number;
        pvalue: number;

        // PolyJson type
        type: 'kendall_tau';
    }

    export namespace KendallTauResult {
        export const type = 'kendall_tau';
    }

    export function isKendallTauResult(toTest: ComputationResult): toTest is KendallTauResult { return KendallTauResult.type === toTest.type; }
}
import {Computation, _Computation} from './../computation';
import {ComputationResult} from './../computation-result';
import {Grouping} from './../../grouping/grouping';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.bivariate.Chi2IndTest
 */
export interface Chi2IndTest extends _Computation {
    xGrouping: Grouping;
    yGrouping: Grouping;

    // PolyJson type
    type: 'chi2_ind_test';
}

export namespace Chi2IndTest {
    export const type = 'chi2_ind_test';
}

export function isChi2IndTest(toTest: Computation): toTest is Chi2IndTest { return Chi2IndTest.type === toTest.type; }

export namespace Chi2IndTest {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.bivariate.Chi2IndTest$Chi2IndTestResult
     */
    export interface Chi2IndTestResult extends _AvailableResult {
        dof: number;
        pvalue: number;
        statistic: number;

        // PolyJson type
        type: 'chi2_ind_test';
    }

    export namespace Chi2IndTestResult {
        export const type = 'chi2_ind_test';
    }

    export function isChi2IndTestResult(toTest: ComputationResult): toTest is Chi2IndTestResult { return Chi2IndTestResult.type === toTest.type; }
}
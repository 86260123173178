import {ACF} from './timeseries/acf';
import {Chi2IndTest} from './bivariate/chi2-ind-test';
import {CountGroups} from './common/count-groups';
import {Count} from './common/count';
import {Covariance} from './bivariate/covariance';
import {DummyComputation} from './common/dummy-computation';
import {DurbinWatson} from './timeseries/durbin-watson';
import {EmpiricalCDF} from './univariate/empirical-cdf';
import {Entropy} from './univariate/entropy';
import {FailedResult} from './failed-result';
import {FetchValues} from './multivariate/fetch-values';
import {Fit2DDistribution} from './bivariate/fit2-ddistribution';
import {FitCurve} from './bivariate/fit-curve';
import {FitDistribution} from './univariate/fit-distribution';
import {GroupedComputation} from './common/grouped-computation';
import {KendallTau} from './bivariate/kendall-tau';
import {KsTest2Samp} from './univariate/ks-test2-samp';
import {Kurtosis} from './univariate/kurtosis';
import {MannKendallTest} from './timeseries/mann-kendall-test';
import {MaxTime} from './univariate/max-time';
import {Max} from './univariate/max';
import {Mean} from './univariate/mean';
import {MinTime} from './univariate/min-time';
import {Min} from './univariate/min';
import {MoodTestNSamp} from './univariate/mood-test-nsamp';
import {MultiComputation} from './common/multi-computation';
import {MutualInformation} from './bivariate/mutual-information';
import {NotComputedResult} from './not-computed-result';
import {OneWayAnova} from './univariate/one-way-anova';
import {PACF} from './timeseries/pacf';
import {PCA} from './multivariate/pca';
import {PairwiseMoodTest} from './univariate/pairwise-mood-test';
import {PairwiseTTest} from './univariate/pairwise-ttest';
import {Pearson} from './bivariate/pearson';
import {Quantiles} from './univariate/quantiles';
import {Sem} from './univariate/sem';
import {Shapiro} from './univariate/shapiro';
import {SignTest1Samp} from './univariate/sign-test1-samp';
import {Skewness} from './univariate/skewness';
import {Spearman} from './bivariate/spearman';
import {StdDev} from './univariate/std-dev';
import {Sum} from './univariate/sum';
import {TTest1Samp} from './univariate/ttest1-samp';
import {TestDistribution} from './univariate/test-distribution';
import {TimeStep} from './univariate/time-step';
import {UnitRootTestADF} from './timeseries/unit-root-test-adf';
import {UnitRootTestKPSS} from './timeseries/unit-root-test-kpss';
import {UnitRootTestZA} from './timeseries/unit-root-test-za';
import {Variance} from './univariate/variance';
import {ZTest1Samp} from './univariate/ztest1-samp';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.ComputationResult
 */
export interface _ComputationResult {
    computationCounts: ComputationResult.ComputationCounts;
}

export type ComputationResult = PACF.PACFResult | DurbinWatson.DurbinWatsonResult | PCA.PCAResult | Quantiles.QuantilesResult | EmpiricalCDF.EmpiricalCDFResult | PairwiseTTest.PairwiseTTestResult | OneWayAnova.OneWayAnovaResult | Sum.SumResult | FitDistribution.FitDistributionResult | MutualInformation.MutualInformationResult | ACF.ACFResult | SignTest1Samp.SignTest1SampResult | Shapiro.ShapiroResult | Min.MinResult | MinTime.MinTimeResult | TimeStep.TimeStepResult | PairwiseMoodTest.PairwiseMoodTestResult | Count.CountResult | MaxTime.MaxTimeResult | MultiComputation.MultiComputationResult | Variance.VarianceResult | MoodTestNSamp.MoodTestNSampResult | KsTest2Samp.KsTest2SampResult | KendallTau.KendallTauResult | FailedResult | UnitRootTestZA.UnitRootTestZAResult | TestDistribution.TestDistributionResult | FitCurve.FitCurveResult | UnitRootTestADF.UnitRootTestADFResult | StdDev.StdDevResult | Max.MaxResult | NotComputedResult | Sem.SemResult | CountGroups.CountGroupsResult | ZTest1Samp.ZTest1SampResult | Entropy.EntropyResult | MannKendallTest.MannKendallTestResult | Covariance.CovarianceResult | UnitRootTestKPSS.UnitRootTestKPSSResult | FetchValues.FetchValuesResult | GroupedComputation.GroupedComputationResult | Pearson.PearsonResult | TTest1Samp.TTest1SampResult | Kurtosis.KurtosisResult | DummyComputation.DummyComputationResult | Fit2DDistribution.Fit2DDistributionResult | Mean.MeanResult | Skewness.SkewnessResult | Spearman.SpearmanResult | Chi2IndTest.Chi2IndTestResult;

export namespace ComputationResult {
    export const type = ["pacf" , "durbin_watson" , "pca" , "quantiles" , "empirical_cdf" , "pairwise_ttest" , "one_way_anova" , "sum" , "fit_distribution" , "mutual_information" , "acf" , "sign_test_1samp" , "shapiro" , "min" , "min_time" , "time_step" , "pairwise_mood_test" , "count" , "max_time" , "multi" , "variance" , "mood_test_nsamp" , "ks_test_2samp" , "kendall_tau" , "failed" , "unit_root_za" , "test_distribution" , "fit_curve" , "unit_root_adf" , "std_dev" , "max" , "not_computed" , "sem" , "count_groups" , "ztest_1samp" , "entropy" , "mann_kendall" , "covariance" , "unit_root_kpss" , "fetch_values" , "grouped" , "pearson" , "ttest_1samp" , "kurtosis" , "dummy" , "fit_2d_distribution" , "mean" , "skewness" , "spearman" , "chi2_ind_test"] as const;
}

export namespace ComputationResult {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.ComputationResult$ComputationCounts
     */
    export interface ComputationCounts {
        computationsCount: number;
        failedComputationsCount: number;
        notComputedCount: number;
    }
}
import {Grouping, _Grouping} from './grouping';
import {GroupingResult, _GroupingResult} from './grouping-result';

/**
 * Generated from com.dataiku.dip.eda.compute.grouping.AnumGrouping
 */
export interface AnumGrouping extends _Grouping {
    column: string;
    groupOthers: boolean;
    maxValues?: number | null;

    // PolyJson type
    type: 'anum';
}

export namespace AnumGrouping {
    export const type = 'anum';
}

export function isAnumGrouping(toTest: Grouping): toTest is AnumGrouping { return AnumGrouping.type === toTest.type; }

export namespace AnumGrouping {
    /**
     * Generated from com.dataiku.dip.eda.compute.grouping.AnumGrouping$AnumGroupingResult
     */
    export interface AnumGroupingResult extends _GroupingResult {
        column: string;
        hasAllValues: boolean;
        hasOthers: boolean;
        values: string[];

        // PolyJson type
        type: 'anum';
    }

    export namespace AnumGroupingResult {
        export const type = 'anum';
    }

    export function isAnumGroupingResult(toTest: GroupingResult): toTest is AnumGroupingResult { return AnumGroupingResult.type === toTest.type; }
}
import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {_AvailableResult} from './../available-result';
import {_TimeSeriesComputation} from './time-series-computation';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.timeseries.MannKendallTest
 */
export interface MannKendallTest extends _TimeSeriesComputation {
    alpha: number;

    // PolyJson type
    type: 'mann_kendall';
}

export namespace MannKendallTest {
    export const type = 'mann_kendall';
}

export function isMannKendallTest(toTest: Computation): toTest is MannKendallTest { return MannKendallTest.type === toTest.type; }

export namespace MannKendallTest {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.timeseries.MannKendallTest$MannKendallTestResult
     */
    export interface MannKendallTestResult extends _AvailableResult {
        intercept?: number | null;
        pValue: number;
        score: number;
        slope?: number | null;
        statistic: number;
        tau: number;
        trend: MannKendallTest.Trend;
        variance: number;

        // PolyJson type
        type: 'mann_kendall';
    }

    export namespace MannKendallTestResult {
        export const type = 'mann_kendall';
    }

    export function isMannKendallTestResult(toTest: ComputationResult): toTest is MannKendallTestResult { return MannKendallTestResult.type === toTest.type; }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.timeseries.MannKendallTest$Trend
     */
    export enum Trend {
        DECREASING = 'DECREASING',
        INCREASING = 'INCREASING',
        NO_TREND = 'NO_TREND'
    }
}
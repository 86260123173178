import {CardResult, _CardResult} from './card-result';
import {Card} from './card';
import {HeatmapParams} from './common/heatmap-params';
import {_MultivariateCard} from './multivariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.CorrelationMatrixCard
 */
export interface CorrelationMatrixCard extends _MultivariateCard {
    heatmapParams: HeatmapParams;
    metric: CorrelationMatrixCard.CorrelationMetric;

    // PolyJson type
    type: 'correlation_matrix';
}

export namespace CorrelationMatrixCard {
    export const type = 'correlation_matrix';
}

export function isCorrelationMatrixCard(toTest: Card): toTest is CorrelationMatrixCard { return CorrelationMatrixCard.type === toTest.type; }

export namespace CorrelationMatrixCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.CorrelationMatrixCard$CorrelationMetric
     */
    export enum CorrelationMetric {
        PEARSON = 'PEARSON',
        SPEARMAN = 'SPEARMAN'
    }

    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.CorrelationMatrixCard$CorrelationMatrixCardResult
     */
    export interface CorrelationMatrixCardResult extends _CardResult {
        partialErrors?: string[] | null;
        scores: number[];

        // PolyJson type
        type: 'correlation_matrix';
    }

    export namespace CorrelationMatrixCardResult {
        export const type = 'correlation_matrix';
    }

    export function isCorrelationMatrixCardResult(toTest: CardResult): toTest is CorrelationMatrixCardResult { return CorrelationMatrixCardResult.type === toTest.type; }
}
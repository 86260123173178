import {AbstractCardContainer, _AbstractCardContainer} from './abstract-card-container';
import {CardResult} from './../card-result';
import {Card} from './../card';
import {Variable} from './../../models/variable';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.common.ColumnCard
 */
export interface ColumnCard extends _AbstractCardContainer {
    column: Variable;

    // PolyJson type
    type: 'column_card';
}

export namespace ColumnCard {
    export const type = 'column_card';
}

export function isColumnCard(toTest: Card): toTest is ColumnCard { return ColumnCard.type === toTest.type; }

export namespace ColumnCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.common.ColumnCard$ColumnCardResult
     */
    export interface ColumnCardResult extends AbstractCardContainer._AbstractCardContainerResult {
        column: Variable;

        // PolyJson type
        type: 'column_card';
    }

    export namespace ColumnCardResult {
        export const type = 'column_card';
    }

    export function isColumnCardResult(toTest: CardResult): toTest is ColumnCardResult { return ColumnCardResult.type === toTest.type; }
}
import {Grouping, _Grouping} from './grouping';
import {GroupingResult, _GroupingResult} from './grouping-result';

/**
 * Generated from com.dataiku.dip.eda.compute.grouping.TopNTimeGrouping
 */
export interface TopNTimeGrouping extends _Grouping {
    column: string;
    n: number;

    // PolyJson type
    type: 'topn_time';
}

export namespace TopNTimeGrouping {
    export const type = 'topn_time';
}

export function isTopNTimeGrouping(toTest: Grouping): toTest is TopNTimeGrouping { return TopNTimeGrouping.type === toTest.type; }

export namespace TopNTimeGrouping {
    /**
     * Generated from com.dataiku.dip.eda.compute.grouping.TopNTimeGrouping$TopNTimeGroupingResult
     */
    export interface TopNTimeGroupingResult extends _GroupingResult {
        // PolyJson type
        type: 'topn_time';
    }

    export namespace TopNTimeGroupingResult {
        export const type = 'topn_time';
    }

    export function isTopNTimeGroupingResult(toTest: GroupingResult): toTest is TopNTimeGroupingResult { return TopNTimeGroupingResult.type === toTest.type; }
}
import {CardResult} from './../cards/card-result';
import {Card} from './../cards/card';
import {Filter} from './../../compute/filtering/filter';
import {InteractiveQuery, _InteractiveQuery} from './interactive-query';
import {Variable} from './../models/variable';

/**
 * Generated from com.dataiku.dip.eda.worksheets.queries.SuggestCards
 */
export interface SuggestCards extends _InteractiveQuery {
    assistantSettings: SuggestCards.AssistantSettings;
    selectedVariables: string[];

    // PolyJson type
    type: 'suggest_cards';
}

export namespace SuggestCards {
    export const type = 'suggest_cards';
}

export function isSuggestCards(toTest: InteractiveQuery): toTest is SuggestCards { return SuggestCards.type === toTest.type; }

export namespace SuggestCards {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.queries.SuggestCards$SuggestCardsResult
     */
    export interface SuggestCardsResult extends InteractiveQuery._InteractiveQueryResult {
        suggestedCards: SuggestCards.SuggestedCard[];
        suggestedVariables: SuggestCards.SuggestedVariable[];

        // PolyJson type
        type: 'suggest_cards';
    }

    export namespace SuggestCardsResult {
        export const type = 'suggest_cards';
    }

    export function isSuggestCardsResult(toTest: InteractiveQuery.InteractiveQueryResult): toTest is SuggestCardsResult { return SuggestCardsResult.type === toTest.type; }

    /**
     * Generated from com.dataiku.dip.eda.worksheets.queries.SuggestCards$SuggestedCard
     */
    export interface SuggestedCard {
        card: Card;
        miniatureCard: Card;
        miniatureResult: CardResult;
        name: string;
    }

    /**
     * Generated from com.dataiku.dip.eda.worksheets.queries.SuggestCards$SuggestedVariable
     */
    export interface SuggestedVariable {
        card: Card;
        explanations: string[];
        individualScore: number;
        name: string;
        result: CardResult;
        totalScore: number;
        type: Variable.Type;
    }

    /**
     * Generated from com.dataiku.dip.eda.worksheets.queries.SuggestCards$AssistantSettings
     */
    export interface AssistantSettings {
        confidenceLevel: number;
        highlightFilter?: Filter | null;
        showConfidenceInterval: boolean;
    }
}
import {EventServerTargetSettings} from './event-server-target-settings';
import {KafkaTargetSettings} from './kafka-target-settings';
import {Log4JTargetSettings} from './log4-jtarget-settings';

/**
 * Generated from com.dataiku.dip.security.audit.model.AuditTrailTargetSettings
 */
export interface _AuditTrailTargetSettings {
    routingKeys: string[];
    routingKeysFiltering: AuditTrailTargetSettings.RoutingKeyFiltering;
    topics: string[];
    topicsFiltering: AuditTrailTargetSettings.TopicsFiltering;
}

export type AuditTrailTargetSettings = KafkaTargetSettings | EventServerTargetSettings | Log4JTargetSettings;

export namespace AuditTrailTargetSettings {
    export const type = ["KAFKA" , "EVENT_SERVER" , "LOG4J"] as const;
}

export namespace AuditTrailTargetSettings {
    /**
     * Generated from com.dataiku.dip.security.audit.model.AuditTrailTargetSettings$RoutingKeyFiltering
     */
    export enum RoutingKeyFiltering {
        ALL = 'ALL',
        SELECTED = 'SELECTED'
    }

    /**
     * Generated from com.dataiku.dip.security.audit.model.AuditTrailTargetSettings$TopicsFiltering
     */
    export enum TopicsFiltering {
        ALL = 'ALL',
        SELECTED = 'SELECTED'
    }
}
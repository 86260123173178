import { Inject, Injectable } from '@angular/core';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { CurrentRouteService } from '@core/nav/current-route.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DeephubImagesDataService } from 'src/generated-sources';
import { DeephubCellData, DeephubColumn } from '@features/deephub/services/abstract-deephub-data-fetcher.service';
import { DeephubImageClassificationReportService, ImageClassificationReport } from './deephub-image-classification-report.service';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { AbstractDeephubReportDataFetcherService, DeephubResultsDataColumns } from '@features/deephub/computer-vision/report/services/abstract-deephub-report-data-fetcher.service';

export interface DeephubImageClassificationReportCellData extends DeephubCellData {
    target: string,
    prediction: string,
    probabilities: Map<string, number>
}

export interface DeephubImageClassificationResultsDataColumns extends DeephubResultsDataColumns {
    probabilities: DeephubColumn[]
}

@UntilDestroy()
@Injectable()
export class DeephubImageClassificationReportDataFetcherService extends AbstractDeephubReportDataFetcherService<ImageClassificationReport, DeephubImagesDataService.ImageClassificationPredictedFilter> {
    constructor(
        DataikuAPI: DataikuAPIService,
        imageClassificationService: DeephubImageClassificationReportService,
        currentRoute: CurrentRouteService,
        colorMapService: ColorMapContextService,
        @Inject('COLOR_PALETTES') COLOR_PALETTES: { [palette: string]: string[]; }
    ) {
        super(DataikuAPI, imageClassificationService, currentRoute, colorMapService, COLOR_PALETTES);
    }

    combineReportFilter(report: ImageClassificationReport, filter: DeephubImagesDataService.ImageClassificationPredictedFilter): DeephubImagesDataService.ImageClassificationPredictedFilter {
        return {...filter, type: "DEEP_HUB_IMAGE_CLASSIFICATION"};
    }

    getDefaultFilter(): Partial<DeephubImagesDataService.ImageClassificationPredictedFilter> {
        return { ascending: false};
    }

    createDataColumns(orderedColumns: string[]): DeephubImageClassificationResultsDataColumns {
        const columns: DeephubImageClassificationResultsDataColumns = {
            target: {
                name: this.coreParams.target_variable
            },
            itemPath: {
                name: this.coreParams.pathColumn
            },
            prediction: {
                name: 'prediction',
            },
            probabilities: []
        };


        for (let i = 0; i < orderedColumns.length; i++) {
            const name = orderedColumns[i];
            if (name.startsWith("proba_")) {
                columns.probabilities.push({name, index: i});
            }
        }

        this.addColumnIndices(columns, orderedColumns);

        return columns;
    }

    rowToCellData(rowContent: string[], i: number, j: number): DeephubImageClassificationReportCellData {
        const columns = this.dataColumns as DeephubImageClassificationResultsDataColumns;
        const cell = {
            itemPath: rowContent[columns.itemPath.index!],
            target: rowContent[columns.target.index!],
            prediction: rowContent[columns.prediction.index!],
            probabilities: new Map<string, number>(),
            imageId: i
        };
        for (const column of columns.probabilities) {
            cell.probabilities.set(column.name, parseFloat(rowContent[column.index!]));
        }

        return cell;
    }
}

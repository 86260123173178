import {ACF} from './acf';
import {Computation, _Computation} from './../computation';
import {DurbinWatson} from './durbin-watson';
import {MannKendallTest} from './mann-kendall-test';
import {PACF} from './pacf';
import {UnitRootTestADF} from './unit-root-test-adf';
import {UnitRootTestKPSS} from './unit-root-test-kpss';
import {UnitRootTestZA} from './unit-root-test-za';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.timeseries.TimeSeriesComputation
 */
export interface _TimeSeriesComputation extends _Computation {
    seriesColumn: string;
    timeColumn: string;
}

export type TimeSeriesComputation = DurbinWatson | UnitRootTestZA | ACF | UnitRootTestKPSS | PACF | MannKendallTest | UnitRootTestADF;

export namespace TimeSeriesComputation {
    export const type = ["durbin_watson" , "unit_root_za" , "acf" , "unit_root_kpss" , "pacf" , "mann_kendall" , "unit_root_adf"] as const;
}

export function isTimeSeriesComputation(toTest: Computation): toTest is TimeSeriesComputation { return (TimeSeriesComputation.type as readonly unknown[]).includes(toTest.type); }
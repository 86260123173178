<div class="card-layout">
    <div class="card-layout__section card-layout__section--horizontal" *ngIf="params.showScreePlot || params.showScatterPlot || params.showLoadingPlot">
        <div class="card-layout__section" *ngIf="params.showScreePlot">
            <div class="card-layout__title">Scree plot</div>
            <div class="card-layout__content">
                <lazy-echart
                    class="chart"
                    [qa]="'eda-pca-scree-plot'"
                    [class.chart--fixed-height-tall]="!params.showComponents && hasFixedHeight"
                    [class.chart--fixed-height]="hasFixedHeight"
                    [options]="varianceChartOptions">
                </lazy-echart>
            </div>
        </div>
        <div class="card-layout__section" *ngIf="params.showScatterPlot">
            <div class="card-layout__title">Scatter plot</div>
            <div class="card-layout__content">
                <lazy-echart
                    class="chart"
                    [qa]="'eda-pca-scatter-plot'"
                    [class.chart--fixed-height-tall]="!params.showComponents && hasFixedHeight"
                    [class.chart--fixed-height]="hasFixedHeight"
                    [options]="scatterPlotChartOptions">
                </lazy-echart>
            </div>
        </div>
        <div class="card-layout__section" *ngIf="params.showLoadingPlot">
            <div class="card-layout__title">Loading plot</div>
            <div class="card-layout__content">
                <lazy-echart
                    class="chart"
                    [qa]="'eda-pca-loading-plot'"
                    [class.chart--fixed-height-tall]="!params.showComponents && hasFixedHeight"
                    [class.chart--fixed-height]="hasFixedHeight"
                    [options]="loadingPlotChartOptions">
                </lazy-echart>
            </div>
        </div>
    </div>
    <div class="card-layout__section  card-layout__section--horizontal" *ngIf="params.showComponents">
        <div class="card-layout__section">
            <div class="card-layout__title">
                Principal components <ng-container *ngIf="params.heatmapParams.filterVariablesWithoutValues">(hiding variables with no value)</ng-container>
                <button
                    *ngIf="!readOnly"
                    class="btn btn--icon menu-button pull-right"
                    [matMenuTriggerFor]="contextualMenu"
                >
                    <i class="icon-dku-ellipsis-vertical"></i>
                </button>

                <mat-menu #contextualMenu>
                    <button
                        mat-menu-item
                        (click)="configureVisualization()"
                    >
                        Configure heatmap...
                    </button>
                </mat-menu>
            </div>
            <div class="card-layout__content">
                <heatmap
                    [xLabels]="heatmapEVLabels"
                    [yLabels]="heatmapColumnLabels"
                    [data]="heatmapData"
                    [readOnly]="readOnly"
                    [heatmapParams]="params.heatmapParams"
                    [qa]="'eda-pca-heatmap'"
                ></heatmap>
            </div>
        </div>
    </div>
</div>

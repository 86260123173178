import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {_AvailableResult} from './../available-result';

export namespace Spearman {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.bivariate.Spearman$SpearmanResult
     */
    export interface SpearmanResult extends _AvailableResult {
        correlation: number;
        pvalue: number;

        // PolyJson type
        type: 'spearman';
    }

    export namespace SpearmanResult {
        export const type = 'spearman';
    }

    export function isSpearmanResult(toTest: ComputationResult): toTest is SpearmanResult { return SpearmanResult.type === toTest.type; }
}

/**
 * Generated from com.dataiku.dip.eda.compute.computations.bivariate.Spearman
 */
export interface Spearman extends Computation._BivariateComputation {
    // PolyJson type
    type: 'spearman';
}

export namespace Spearman {
    export const type = 'spearman';
}

export function isSpearman(toTest: Computation): toTest is Spearman { return Spearman.type === toTest.type; }
import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.TimeStep
 */
export interface TimeStep extends Computation._UnivariateComputation {
    maxSteps: number;

    // PolyJson type
    type: 'time_step';
}

export namespace TimeStep {
    export const type = 'time_step';
}

export function isTimeStep(toTest: Computation): toTest is TimeStep { return TimeStep.type === toTest.type; }

export namespace TimeStep.TimeStepResult {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.TimeStep$TimeStepResult$Step
     */
    export interface _Step {}

    export type Step = TimeStep.TimeStepResult.IsoDurationStep | TimeStep.TimeStepResult.BusinessDaysStep;

    export namespace Step {
        export const type = ["iso_duration_step" , "business_days_step"] as const;
    }

    export function isStep(toTest: TimeStep.TimeStepResult.Step): toTest is Step { return (Step.type as readonly unknown[]).includes(toTest.type); }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.TimeStep$TimeStepResult$BusinessDaysStep
     */
    export interface BusinessDaysStep extends TimeStep.TimeStepResult._Step {
        weekMask: string;

        // PolyJson type
        type: 'business_days_step';
    }

    export namespace BusinessDaysStep {
        export const type = 'business_days_step';
    }

    export function isBusinessDaysStep(toTest: TimeStep.TimeStepResult.Step): toTest is BusinessDaysStep { return BusinessDaysStep.type === toTest.type; }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.TimeStep$TimeStepResult$IsoDurationStep
     */
    export interface IsoDurationStep extends TimeStep.TimeStepResult._Step {
        duration: string;

        // PolyJson type
        type: 'iso_duration_step';
    }

    export namespace IsoDurationStep {
        export const type = 'iso_duration_step';
    }

    export function isIsoDurationStep(toTest: TimeStep.TimeStepResult.Step): toTest is IsoDurationStep { return IsoDurationStep.type === toTest.type; }
}

export namespace TimeStep {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.TimeStep$TimeStepResult
     */
    export interface TimeStepResult extends _AvailableResult {
        nSteps: number;
        steps: TimeStep.TimeStepResult.Step[];

        // PolyJson type
        type: 'time_step';
    }

    export namespace TimeStepResult {
        export const type = 'time_step';
    }

    export function isTimeStepResult(toTest: ComputationResult): toTest is TimeStepResult { return TimeStepResult.type === toTest.type; }
}
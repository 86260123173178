import {CardResult, _CardResult} from './../card-result';
import {Card} from './../card';
import {Filter} from './../../../compute/filtering/filter';
import {_UnivariateCard} from './../univariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.univariate_header.CategoricalHistogramCard
 */
export interface CategoricalHistogramCard extends _UnivariateCard {
    groupOthers: boolean;
    highlightFilter?: Filter | null;
    maxValues: number;

    // PolyJson type
    type: 'categorical_histogram';
}

export namespace CategoricalHistogramCard {
    export const type = 'categorical_histogram';
}

export function isCategoricalHistogramCard(toTest: Card): toTest is CategoricalHistogramCard { return CategoricalHistogramCard.type === toTest.type; }

export namespace CategoricalHistogramCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.univariate_header.CategoricalHistogramCard$CategoricalHistogramCardResult
     */
    export interface CategoricalHistogramCardResult extends _CardResult {
        histogram: CategoricalHistogramCard.HistogramData;

        // PolyJson type
        type: 'categorical_histogram';
    }

    export namespace CategoricalHistogramCardResult {
        export const type = 'categorical_histogram';
    }

    export function isCategoricalHistogramCardResult(toTest: CardResult): toTest is CategoricalHistogramCardResult { return CategoricalHistogramCardResult.type === toTest.type; }

    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.univariate_header.CategoricalHistogramCard$HistogramData
     */
    export interface HistogramData {
        counts: number[];
        filters: Filter[];
        highlightedCounts?: number[] | null;
        totalCount: number;
    }
}
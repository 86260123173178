import { OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { HeatmapParams } from 'src/generated-sources';
import { DeephubReport, DeephubReportService } from '../services/abstract-deephub-report.service';

export interface MatrixData {
    data: number[][];
    xCategories: string[];
    yCategories: string[];
    params: HeatmapParams
}

export abstract class DeephubAbstractReportConfusionMatrixComponent<R extends DeephubReport, S extends DeephubReportService<R>, > implements OnInit {
    matrix$: Observable<MatrixData>;
    filterMatrix$: Observable<MatrixData>;
    categories: string[] = [];
    filteredCategoryMap$: Observable<{
        [key: string]: boolean
    }>;

    readonly NO_DETECTION_LABEL = 'Not Detected';
    readonly NO_GROUND_TRUTH_LABEL = 'Not an object';

    form = this.formBuilder.group({
        selectedCategories: new FormControl()
    })

    constructor(
        protected reportService: S,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit(): void {
        this.matrix$ = this.reportService.getReport()
            .pipe(
                debounceTime(400),
                map(report => this.createMatrix(report)
            )
        );

        this.filteredCategoryMap$ = this.form.get('selectedCategories')!.valueChanges.pipe(
            map((selectedCategories: string[]) => {
                return (selectedCategories || []).reduce((map: { [key: string]: boolean }, category: string) => {
                    map[category] = true;
                    return map;
                }, {});
            })
        );
    }

    abstract matrixClick(clickedCell: { xIndex: number, yIndex: number} | null): void;

    abstract createMatrix(report: R): MatrixData;
}

import {AbstractNSampTestCard, _AbstractNSampTestCard} from './common/abstract-nsamp-test-card';
import {CardResult} from './card-result';
import {Card} from './card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.MoodTestNSampCard
 */
export interface MoodTestNSampCard extends _AbstractNSampTestCard {
    // PolyJson type
    type: 'mood_nsamp';
}

export namespace MoodTestNSampCard {
    export const type = 'mood_nsamp';
}

export function isMoodTestNSampCard(toTest: Card): toTest is MoodTestNSampCard { return MoodTestNSampCard.type === toTest.type; }

export namespace MoodTestNSampCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.MoodTestNSampCard$MoodTestNSampCardResult
     */
    export interface MoodTestNSampCardResult extends AbstractNSampTestCard._AbstractNSampleTestCardResult {
        pvalue: number;
        statistic: number;

        // PolyJson type
        type: 'mood_nsamp';
    }

    export namespace MoodTestNSampCardResult {
        export const type = 'mood_nsamp';
    }

    export function isMoodTestNSampCardResult(toTest: CardResult): toTest is MoodTestNSampCardResult { return MoodTestNSampCardResult.type === toTest.type; }
}
import {CardResult, _CardResult} from './../card-result';
import {Card} from './../card';
import {SummaryCardBuilder} from './../common/summary-card-builder';
import {_UnivariateCard} from './../univariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateSummaryStatsCard
 */
export interface UnivariateSummaryStatsCard extends _UnivariateCard {
    compute: UnivariateSummaryStatsCard.StatsToCompute;
    confidenceLevel: number;
    showConfidenceInterval: boolean;

    // PolyJson type
    type: 'univariate_summary';
}

export namespace UnivariateSummaryStatsCard {
    export const type = 'univariate_summary';
}

export function isUnivariateSummaryStatsCard(toTest: Card): toTest is UnivariateSummaryStatsCard { return UnivariateSummaryStatsCard.type === toTest.type; }

export namespace UnivariateSummaryStatsCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateSummaryStatsCard$StatsToCompute
     */
    export interface StatsToCompute {
        completeCount: boolean;
        distinctCount: boolean;
        iqr: boolean;
        kurtosis: boolean;
        max: boolean;
        mean: boolean;
        median: boolean;
        min: boolean;
        mode: boolean;
        nbEmpty: boolean;
        nbNonEmpty: boolean;
        nonZeroRatio: boolean;
        range: boolean;
        sem: boolean;
        skewness: boolean;
        std: boolean;
        sum: boolean;
        totalCount: boolean;
        variance: boolean;
        zeroCount: boolean;
        zeroRatio: boolean;
    }

    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateSummaryStatsCard$UnivariateSummaryStatsCardResult
     */
    export interface UnivariateSummaryStatsCardResult extends _CardResult {
        stats: SummaryCardBuilder.ComputedStat[];

        // PolyJson type
        type: 'univariate_summary';
    }

    export namespace UnivariateSummaryStatsCardResult {
        export const type = 'univariate_summary';
    }

    export function isUnivariateSummaryStatsCardResult(toTest: CardResult): toTest is UnivariateSummaryStatsCardResult { return UnivariateSummaryStatsCardResult.type === toTest.type; }
}
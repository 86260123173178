import { AnyLoc } from "src/generated-sources";

export class ManagedFolder {

    loc: AnyLoc;
    contextProjectKey: string;

    constructor(loc: AnyLoc, contextProjectKey: string) {
        this.loc = loc;
        this.contextProjectKey = contextProjectKey;
    }

    getImagePath(itemPath: string): string {
        let contentType = 'image/jpeg';
        // keep in sync with PathStats::SUPPORTED_IMAGES
        if (itemPath.endsWith('.png')) {
            contentType = 'image/png';
        }
        return `/dip/api/managedfolder/preview-image?contextProjectKey=${encodeURIComponent(this.contextProjectKey)}&projectKey=${encodeURIComponent(this.loc.projectKey)}&odbId=${encodeURIComponent(this.loc.id)}&itemPath=${encodeURIComponent(itemPath)}&contentType=${encodeURIComponent(contentType)}`;
    }
}

import {CardResult, _CardResult} from './card-result';
import {Card} from './card';
import {Filter} from './../../compute/filtering/filter';
import {SplitBySpec} from './../models/split-by-spec';
import {_TrivariateCard} from './trivariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.ScatterPlot3DCard
 */
export interface ScatterPlot3DCard extends _TrivariateCard {
    colorBy?: SplitBySpec | null;
    highlightFilter?: Filter | null;
    maxNumberOfPoints: number;
    symbolSize: number;

    // PolyJson type
    type: 'scatter_plot_3d';
}

export namespace ScatterPlot3DCard {
    export const type = 'scatter_plot_3d';
}

export function isScatterPlot3DCard(toTest: Card): toTest is ScatterPlot3DCard { return ScatterPlot3DCard.type === toTest.type; }

export namespace ScatterPlot3DCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.ScatterPlot3DCard$ScatterPlot3DCardResult
     */
    export interface ScatterPlot3DCardResult extends _CardResult {
        groups: Filter[];
        series3d: ScatterPlot3DCard.ScatterPlot3DSeries[];

        // PolyJson type
        type: 'scatter_plot_3d';
    }

    export namespace ScatterPlot3DCardResult {
        export const type = 'scatter_plot_3d';
    }

    export function isScatterPlot3DCardResult(toTest: CardResult): toTest is ScatterPlot3DCardResult { return ScatterPlot3DCardResult.type === toTest.type; }

    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.ScatterPlot3DCard$ScatterPlot3DSeries
     */
    export interface ScatterPlot3DSeries {
        groupIndex: number;
        isHighlighted: boolean;
        xSeries: number[];
        ySeries: number[];
        zSeries: number[];
    }
}
import {AbstractHeaderCard, _AbstractHeaderCard} from './../common/abstract-header-card';
import {CardResult} from './../card-result';
import {Card} from './../card';
import {Variable} from './../../models/variable';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateHeaderCard
 */
export interface BivariateHeaderCard extends _AbstractHeaderCard {
    showBoxPlot: boolean;
    showFrequencyTable: boolean;
    showHistogram: boolean;
    showMosaicPlot: boolean;
    showScatterPlot: boolean;
    showSummary: boolean;
    yColumn: Variable;

    // PolyJson type
    type: 'bivariate_header';
}

export namespace BivariateHeaderCard {
    export const type = 'bivariate_header';
}

export function isBivariateHeaderCard(toTest: Card): toTest is BivariateHeaderCard { return BivariateHeaderCard.type === toTest.type; }

export namespace BivariateHeaderCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateHeaderCard$BivariateHeaderCardResult
     */
    export interface BivariateHeaderCardResult extends AbstractHeaderCard._AbstractHeaderCardResult {
        // PolyJson type
        type: 'bivariate_header';
    }

    export namespace BivariateHeaderCardResult {
        export const type = 'bivariate_header';
    }

    export function isBivariateHeaderCardResult(toTest: CardResult): toTest is BivariateHeaderCardResult { return BivariateHeaderCardResult.type === toTest.type; }
}
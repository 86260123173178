import {AuditTrailTargetSettings, _AuditTrailTargetSettings} from './audit-trail-target-settings';

/**
 * Generated from com.dataiku.dip.security.audit.model.Log4JTargetSettings
 */
export interface Log4JTargetSettings extends _AuditTrailTargetSettings {
    appendTopicToLogger: boolean;
    loggerSuffix: string;

    // PolyJson type
    type: 'LOG4J';
}

export namespace Log4JTargetSettings {
    export const type = 'LOG4J';
}

export function isLog4JTargetSettings(toTest: AuditTrailTargetSettings): toTest is Log4JTargetSettings { return Log4JTargetSettings.type === toTest.type; }
// In this file, we make Angular components available to AngularJS
//
// - ! - WARNING - ! -
//
// - You have to redeclare the selector for directives, with the AngularJS convention:
// ex: for myDirective to match my-directive markup.
// The selector metadata of the downgraded Angular component is ignored.
// For simplicity, make sure what you write corresponds to the component definition,
// In the Component file:
//     selector: 'my-directive',
// here:
//  angular.module(...).directive('myDirective', ...)

import { Type } from '@angular/core';
import { DowngradedCredentialsListComponent } from '@app/widgets/lists/credentials-list/downgraded/downgraded-credentials-list.component';
import { DowngradedValuesListComponent } from '@app/widgets/lists/values-list/downgraded/downgraded-values-list.component';
import { DowngradedKeyValuesListComponent } from '@app/widgets/lists/key-values-list/downgraded/downgraded-key-values-list.component';
import { downgradeComponent } from '@angular/upgrade/static';
import { fairAny, LazyEchartComponent } from 'dku-frontend-core';
import { EdaComponent } from '@features/eda/eda.component';
import { InsightCardComponent } from '@features/eda/worksheet/cards/insight-card/insight-card.component';
import { DowngradedConnectionPropertiesListComponent } from '@app/widgets/lists/connection-properties-list/downgraded/downgraded-connection-properties-list.component';
import { WorksheetRedirectionPageComponent } from '@features/eda/pages/worksheet-redirection-page/worksheet-redirection-page.component';
import { DowngradedEditTypeaheadInputComponent } from '@app/widgets/inputs/edit-typeahead-input/downgraded/downgraded-edit-typeahead-input.component';
import { DeephubImageClassificationDesignImageFeedComponent } from '@features/deephub/image-classification/design/image-feed-provider/deephub-image-classification-design-image-feed.component';
import { DeephubObjectDetectionDesignImageFeedComponent } from '@features/deephub/object-detection/design/image-feed-provider/deephub-object-detection-design-image-feed.component';
import { DeephubObjectDetectionReportComponent } from '@features/deephub/object-detection/report/deephub-object-detection-report.component';
import { DeephubImageClassificationReportComponent } from '@features/deephub/image-classification/report/deephub-image-classification-report.component';
import { WorkspacesLeftMenuComponent } from '@features/workspaces/layout/workspaces-left-menu/workspaces-left-menu.component';
import { WorkspaceHeaderComponent } from '@features/workspaces/workspace/workspace-header/workspace-header.component';
import { WorkspacePermissionsEditionComponent } from '@features/workspaces/workspace/workspace-permissions-edition/workspace-permissions-edition.component';
import { WorkspaceGeneralEditionComponent } from '@features/workspaces/workspace/workspace-general-edition/workspace-general-edition.component';
import { WorkspaceObjectLinkFaviconComponent } from '@features/workspaces/workspace/workspace-object-link-favicon/workspace-object-link-favicon.component';
import { WorkspacesComponent } from '@features/workspaces/workspaces.component';
import { DeephubDesignDataAugmentationComponent } from '@features/deephub/computer-vision/design/data-augmentation/deephub-design-data-augmentation.component';

function directive(ajsDirective: string, component: Type<any>, inputs?: string[], outputs?: string[]): Type<any> {
    const angularJS = (window as fairAny).angular;
    angularJS.module('dataiku').directive(ajsDirective, downgradeComponent({ component, inputs, outputs, propagateDigest: false }));
    return component;
}

export function downgradeComponents(): Array<Type<any>> {
    return [
        directive('ng2KeyValuesList', DowngradedKeyValuesListComponent),
        directive('ng2ValuesList', DowngradedValuesListComponent),
        directive('ng2CredentialsList', DowngradedCredentialsListComponent),
        directive('ng2ConnectionPropertiesList', DowngradedConnectionPropertiesListComponent),
        directive('ng2Eda', EdaComponent),
        directive('ng2EdaWorksheetRedirectionPage', WorksheetRedirectionPageComponent),
        directive('ng2EdaInsight', InsightCardComponent, ['insight', 'readOnly'], ['updated']),
        directive('ng2LazyEchart', LazyEchartComponent),
        directive('ng2Typeahead', DowngradedEditTypeaheadInputComponent),
        directive("ng2DeephubImageClassificationDesignImageFeed", DeephubImageClassificationDesignImageFeedComponent),
        directive("ng2DeephubObjectDetectionDesignImageFeed", DeephubObjectDetectionDesignImageFeedComponent),
        directive("ng2DeephubDesignDataAugmentation", DeephubDesignDataAugmentationComponent),
        directive("ng2DeephubObjectDetectionReport", DeephubObjectDetectionReportComponent),
        directive("ng2DeephubImageClassificationReport", DeephubImageClassificationReportComponent),
        directive("ng2Workspaces", WorkspacesComponent),
        directive("ng2WorkspacesLeftMenu", WorkspacesLeftMenuComponent),
        directive("ng2WorkspaceHeader", WorkspaceHeaderComponent),
        directive("ng2WorkspacePermissionsEdition", WorkspacePermissionsEditionComponent),
        directive("ng2WorkspaceGeneralEdition", WorkspaceGeneralEditionComponent),
        directive("ng2WorkspaceObjectLinkFavicon", WorkspaceObjectLinkFaviconComponent),
    ];
}

import {AnumGrouping} from './anum-grouping';
import {BinnedGrouping} from './binned-grouping';
import {CrossGrouping} from './cross-grouping';
import {MergeGrouping} from './merge-grouping';
import {SubsampledGrouping} from './subsampled-grouping';
import {SubsetGrouping} from './subset-grouping';
import {TopNTimeGrouping} from './top-ntime-grouping';
import {UnionGrouping} from './union-grouping';

/**
 * Generated from com.dataiku.dip.eda.compute.grouping.GroupingResult
 */
export interface _GroupingResult {}

export type GroupingResult = CrossGrouping.CrossGroupingResult | TopNTimeGrouping.TopNTimeGroupingResult | SubsetGrouping.SubsetGroupingResult | BinnedGrouping.BinnedGroupingResult | UnionGrouping.UnionGroupingResult | MergeGrouping.MergeGroupingResult | AnumGrouping.AnumGroupingResult | SubsampledGrouping.SubsampledGroupingResult;

export namespace GroupingResult {
    export const type = ["cross" , "topn_time" , "subset" , "binned" , "union" , "merge" , "anum" , "subsampled"] as const;
}
import {Card, _Card} from './card';
import {ScatterPlot3DCard} from './scatter-plot3-dcard';
import {Variable} from './../models/variable';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.TrivariateCard
 */
export interface _TrivariateCard extends _Card {
    xColumn: Variable;
    yColumn: Variable;
    zColumn: Variable;
}

export type TrivariateCard = ScatterPlot3DCard;

export namespace TrivariateCard {
    export const type = ["scatter_plot_3d"] as const;
}

export function isTrivariateCard(toTest: Card): toTest is TrivariateCard { return (TrivariateCard.type as readonly unknown[]).includes(toTest.type); }
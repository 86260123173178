import {BinningMode} from './../../models/binning-mode';
import {BivariateFrequencyTableCard} from './bivariate-frequency-table-card';
import {BivariateHistogramCard} from './bivariate-histogram-card';
import {Card} from './../card';
import {MosaicPlotCard} from './mosaic-plot-card';
import {_BivariateCard} from './../bivariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.bivariate_header.Abstract2DPivotCard
 */
export interface _Abstract2DPivotCard extends _BivariateCard {
    groupWithOthersX: boolean;
    groupWithOthersY: boolean;
    maxValuesX: number;
    maxValuesY: number;
    xBinningMode: BinningMode;
    xCustomBinningBoundaries: number[];
    yBinningMode: BinningMode;
    yCustomBinningBoundaries: number[];
}

export type Abstract2DPivotCard = BivariateFrequencyTableCard | BivariateHistogramCard | MosaicPlotCard;

export namespace Abstract2DPivotCard {
    export const type = ["bivariate_frequency_table" , "bivariate_histogram" , "mosaic_plot"] as const;
}

export function isAbstract2DPivotCard(toTest: Card): toTest is Abstract2DPivotCard { return (Abstract2DPivotCard.type as readonly unknown[]).includes(toTest.type); }
import {Computation, _Computation} from './../computation';
import {ComputationResult} from './../computation-result';
import {GroupingResult} from './../../grouping/grouping-result';
import {Grouping} from './../../grouping/grouping';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.common.GroupedComputation
 */
export interface GroupedComputation extends _Computation {
    computation: Computation;
    grouping: Grouping;

    // PolyJson type
    type: 'grouped';
}

export namespace GroupedComputation {
    export const type = 'grouped';
}

export function isGroupedComputation(toTest: Computation): toTest is GroupedComputation { return GroupedComputation.type === toTest.type; }

export namespace GroupedComputation {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.common.GroupedComputation$GroupedComputationResult
     */
    export interface GroupedComputationResult extends _AvailableResult {
        groups: GroupingResult;
        results: ComputationResult[];

        // PolyJson type
        type: 'grouped';
    }

    export namespace GroupedComputationResult {
        export const type = 'grouped';
    }

    export function isGroupedComputationResult(toTest: ComputationResult): toTest is GroupedComputationResult { return GroupedComputationResult.type === toTest.type; }
}
import {Filter, _Filter} from './filter';

/**
 * Generated from com.dataiku.dip.eda.compute.filtering.IntervalFilter
 */
export interface IntervalFilter extends _Filter {
    closed: IntervalFilter.ClosedMode;
    column: string;
    left: number;
    right: number;

    // PolyJson type
    type: 'interval';
}

export namespace IntervalFilter {
    export const type = 'interval';
}

export function isIntervalFilter(toTest: Filter): toTest is IntervalFilter { return IntervalFilter.type === toTest.type; }

export namespace IntervalFilter {
    /**
     * Generated from com.dataiku.dip.eda.compute.filtering.IntervalFilter$ClosedMode
     */
    export enum ClosedMode {
        LEFT = 'LEFT',
        RIGHT = 'RIGHT',
        BOTH = 'BOTH',
        NEITHER = 'NEITHER'
    }
}
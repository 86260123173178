import {Curve, _Curve} from './curve';

/**
 * Generated from com.dataiku.dip.eda.compute.curves.PolynomialCurve
 */
export interface PolynomialCurve extends _Curve {
    degree: number;

    // PolyJson type
    type: 'polynomial';
}

export namespace PolynomialCurve {
    export const type = 'polynomial';
}

export function isPolynomialCurve(toTest: Curve): toTest is PolynomialCurve { return PolynomialCurve.type === toTest.type; }

export namespace PolynomialCurve {
    /**
     * Generated from com.dataiku.dip.eda.compute.curves.PolynomialCurve$ParametrizedPolynomialCurve
     */
    export interface ParametrizedPolynomialCurve extends Curve._ParametrizedCurve {
        coefs: number[];

        // PolyJson type
        type: 'polynomial';
    }

    export namespace ParametrizedPolynomialCurve {
        export const type = 'polynomial';
    }

    export function isParametrizedPolynomialCurve(toTest: Curve.ParametrizedCurve): toTest is ParametrizedPolynomialCurve { return ParametrizedPolynomialCurve.type === toTest.type; }
}
import {ACFPlotCard} from './acfplot-card';
import {BivariateBoxPlotCard} from './bivariate_header/bivariate-box-plot-card';
import {BivariateFrequencyTableCard} from './bivariate_header/bivariate-frequency-table-card';
import {BivariateHeaderCard} from './bivariate_header/bivariate-header-card';
import {BivariateHistogramCard} from './bivariate_header/bivariate-histogram-card';
import {BivariateSummaryStatsCard} from './bivariate_header/bivariate-summary-stats-card';
import {CDFPlotCard} from './cdfplot-card';
import {CategoricalHistogramCard} from './univariate_header/categorical-histogram-card';
import {Chi2IndTestCard} from './chi2-ind-test-card';
import {ColumnCard} from './common/column-card';
import {CorrelationMatrixCard} from './correlation-matrix-card';
import {DurbinWatsonCard} from './durbin-watson-card';
import {Filter} from './../../compute/filtering/filter';
import {Fit2DDistributionCard} from './fit2-ddistribution-card';
import {FitCurveCard} from './fit-curve-card';
import {FitDistributionCard} from './fit-distribution-card';
import {KsTest2SampCard} from './ks-test2-samp-card';
import {MannKendallTestCard} from './mann-kendall-test-card';
import {MoodTest2SampCard} from './mood-test2-samp-card';
import {MoodTestNSampCard} from './mood-test-nsamp-card';
import {MosaicPlotCard} from './bivariate_header/mosaic-plot-card';
import {NumericalHistogramCard} from './univariate_header/numerical-histogram-card';
import {OneWayANOVACard} from './one-way-anovacard';
import {PCACard} from './pcacard';
import {PairwiseMoodTestCard} from './pairwise-mood-test-card';
import {PairwiseTTestCard} from './pairwise-ttest-card';
import {ParallelCoordinatesPlotCard} from './parallel-coordinates-plot-card';
import {PlaygroundCard} from './playground-card';
import {QuantilesTableCard} from './univariate_header/quantiles-table-card';
import {ScatterPlot3DCard} from './scatter-plot3-dcard';
import {ScatterPlotCard} from './bivariate_header/scatter-plot-card';
import {ShapiroNormalityTestCard} from './shapiro-normality-test-card';
import {SignTest1SampCard} from './sign-test1-samp-card';
import {SplitBySpec} from './../models/split-by-spec';
import {TTest2SampCard} from './ttest2-samp-card';
import {TZTest1SampCard} from './tztest1-samp-card';
import {UnitRootTestADFCard} from './unit-root-test-adfcard';
import {UnitRootTestKPSSCard} from './unit-root-test-kpsscard';
import {UnitRootTestZACard} from './unit-root-test-zacard';
import {UnivariateFrequencyTableCard} from './univariate_header/univariate-frequency-table-card';
import {UnivariateHeaderCard} from './univariate_header/univariate-header-card';
import {UnivariateSummaryStatsCard} from './univariate_header/univariate-summary-stats-card';
import {WorksheetRootCard} from './worksheet-root-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.Card
 */
export interface _Card {
    filter?: Filter | null;
    id: string;
    splitBy?: SplitBySpec | null;
}

export type Card = UnivariateFrequencyTableCard | BivariateHeaderCard | ScatterPlot3DCard | PlaygroundCard | Chi2IndTestCard | CorrelationMatrixCard | PairwiseMoodTestCard | SignTest1SampCard | BivariateFrequencyTableCard | CategoricalHistogramCard | MoodTest2SampCard | TTest2SampCard | BivariateHistogramCard | ShapiroNormalityTestCard | QuantilesTableCard | KsTest2SampCard | UnitRootTestZACard | TZTest1SampCard | MannKendallTestCard | FitCurveCard | MosaicPlotCard | WorksheetRootCard | UnivariateHeaderCard | PCACard | FitDistributionCard | Fit2DDistributionCard | ColumnCard | ScatterPlotCard | MoodTestNSampCard | BivariateSummaryStatsCard | CDFPlotCard | OneWayANOVACard | PairwiseTTestCard | DurbinWatsonCard | UnitRootTestADFCard | UnitRootTestKPSSCard | UnivariateSummaryStatsCard | NumericalHistogramCard | ParallelCoordinatesPlotCard | BivariateBoxPlotCard | ACFPlotCard;

export namespace Card {
    export const type = ["univariate_frequency_table" , "bivariate_header" , "scatter_plot_3d" , "playground" , "chi2_independence_test" , "correlation_matrix" , "pairwise_mood" , "sign_test_1samp" , "bivariate_frequency_table" , "categorical_histogram" , "mood_test_2samp" , "ttest_2samp" , "bivariate_histogram" , "shapiro" , "quantile_table" , "ks_test_2samp" , "unit_root_test_za" , "ttest_ztest_1samp" , "mann_kendall_test" , "fit_curve" , "mosaic_plot" , "worksheet_root" , "univariate_header" , "pca" , "fit_distribution" , "fit_2d_distribution" , "column_card" , "scatter_plot" , "mood_nsamp" , "bivariate_summary" , "cdf_plot" , "oneway_anova" , "pairwise_ttest" , "durbin_watson" , "unit_root_test_adf" , "unit_root_test_kpss" , "univariate_summary" , "numerical_histogram" , "parallel_coordinates_plot" , "bivariate_box_plot" , "acf_plot"] as const;
}
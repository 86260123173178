export namespace DeephubImagesDataService {
    /**
     * Generated from com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$Sorting
     */
    export interface Sorting {
        ascending: boolean;
        sortBy: DeephubImagesDataService.SortBy;
    }

    /**
     * Generated from com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$SortBy
     */
    export enum SortBy {
        IOU = 'IOU',
        CONFIDENCE = 'CONFIDENCE'
    }

    /**
     * Generated from com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$ObjectDetectionPredictedFilter
     */
    export interface ObjectDetectionPredictedFilter extends DeephubImagesDataService._ComputerVisionPredictedFilter {
        detection?: DeephubImagesDataService.Category | null;
        groundTruth?: DeephubImagesDataService.Category | null;
        minConfidence: number;
        minIOU: number;
        sorting: DeephubImagesDataService.Sorting;

        // PolyJson type
        type: 'DEEP_HUB_IMAGE_OBJECT_DETECTION';
    }

    export namespace ObjectDetectionPredictedFilter {
        export const type = 'DEEP_HUB_IMAGE_OBJECT_DETECTION';
    }

    export function isObjectDetectionPredictedFilter(toTest: DeephubImagesDataService.ComputerVisionPredictedFilter): toTest is ObjectDetectionPredictedFilter { return ObjectDetectionPredictedFilter.type === toTest.type; }

    /**
     * Generated from com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$ComputerVisionPredictedFilter
     */
    export interface _ComputerVisionPredictedFilter {}

    export type ComputerVisionPredictedFilter = DeephubImagesDataService.ImageClassificationPredictedFilter | DeephubImagesDataService.ObjectDetectionPredictedFilter;

    export namespace ComputerVisionPredictedFilter {
        export const type = ["DEEP_HUB_IMAGE_CLASSIFICATION" , "DEEP_HUB_IMAGE_OBJECT_DETECTION"] as const;
    }

    export function isComputerVisionPredictedFilter(toTest: DeephubImagesDataService.ComputerVisionPredictedFilter): toTest is ComputerVisionPredictedFilter { return (ComputerVisionPredictedFilter.type as readonly unknown[]).includes(toTest.type); }

    /**
     * Generated from com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$CategoryType
     */
    export enum CategoryType {
        ANY = 'ANY',
        NONE = 'NONE',
        ONE = 'ONE'
    }

    /**
     * Generated from com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$ImageClassificationPredictedFilter
     */
    export interface ImageClassificationPredictedFilter extends DeephubImagesDataService._ComputerVisionPredictedFilter {
        ascending: boolean;
        detection?: string | null;
        groundTruth?: string | null;

        // PolyJson type
        type: 'DEEP_HUB_IMAGE_CLASSIFICATION';
    }

    export namespace ImageClassificationPredictedFilter {
        export const type = 'DEEP_HUB_IMAGE_CLASSIFICATION';
    }

    export function isImageClassificationPredictedFilter(toTest: DeephubImagesDataService.ComputerVisionPredictedFilter): toTest is ImageClassificationPredictedFilter { return ImageClassificationPredictedFilter.type === toTest.type; }

    /**
     * Generated from com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$Category
     */
    export interface Category {
        type: DeephubImagesDataService.CategoryType;
        value: string;
    }

    /**
     * Generated from com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$DeepHubDesignFilterRequest
     */
    export interface DeepHubDesignFilterRequest {
        targetCategories: string[];
    }
}
import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {CorrelationFunction, _CorrelationFunction} from './correlation-function';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.timeseries.ACF
 */
export interface ACF extends _CorrelationFunction {
    adjusted: boolean;
    alpha: number;
    nLags?: number | null;

    // PolyJson type
    type: 'acf';
}

export namespace ACF {
    export const type = 'acf';
}

export function isACF(toTest: Computation): toTest is ACF { return ACF.type === toTest.type; }

export namespace ACF {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.timeseries.ACF$ACFResult
     */
    export interface ACFResult extends CorrelationFunction._CorrelationFunctionResult {
        // PolyJson type
        type: 'acf';
    }

    export namespace ACFResult {
        export const type = 'acf';
    }

    export function isACFResult(toTest: ComputationResult): toTest is ACFResult { return ACFResult.type === toTest.type; }
}
import { Injectable } from '@angular/core';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { CurrentRouteService } from '@core/nav/current-route.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { DeephubImagesDataService, ResolvedDeepHubPredictionCoreParams, SerializedMemTableV2, SerializedTableChunk } from 'src/generated-sources';
import { AbstractDeephubDataFetcherService, DeephubColumn, DeephubDataColumns } from '@features/deephub/services/abstract-deephub-data-fetcher.service';
import { DeephubReport, DeephubReportService } from './abstract-deephub-report.service';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { resolveSmartName } from '@utils/loc';

export interface DeephubResultsDataColumns extends DeephubDataColumns {
    prediction: DeephubColumn,
}

@UntilDestroy()
@Injectable()
export abstract class AbstractDeephubReportDataFetcherService<R extends DeephubReport, F extends DeephubImagesDataService.ComputerVisionPredictedFilter> extends AbstractDeephubDataFetcherService {
    coreParams: ResolvedDeepHubPredictionCoreParams;
    dataColumns: DeephubResultsDataColumns;

    report$: Observable<R>;
    filter$ = new BehaviorSubject<Partial<F> | null>(this.getDefaultFilter());
    fetchTrigger$: Observable<[R, Partial<F> | null]>;

    constructor(
        private DataikuAPI: DataikuAPIService,
        private reportService: DeephubReportService<R>,
        currentRoute: CurrentRouteService,
        private colorMapService: ColorMapContextService,
        private COLOR_PALETTES: { [palette: string]: string[]; },
    ) {
        super();
        this.projectKey = currentRoute.projectKey;
        this.report$ = this.reportService.getReport().pipe(
            debounceTime(400)
        );

        this.report$.
            pipe(
                untilDestroyed(this)
            ).subscribe((report) => {
                this.coreParams = report.coreParams;
                this.managedFolderLoc = resolveSmartName(this.projectKey, report.coreParams.managedFolderSmartId);
                this.colorMapService.setMapping(report.categories, this.COLOR_PALETTES.highContrast);

                this.onDataChanged();
            });

        this.fetchTrigger$ = combineLatest([this.report$, this.filter$])
            .pipe(
                map(([report, filter]) => [report, filter ? this.combineReportFilter(report, filter as F) : filter]),
            );
    }

    abstract combineReportFilter(report: R, filter: F): F;

    abstract getDefaultFilter(): Partial<F>;

    refreshSample(): Observable<SerializedMemTableV2> {
        return this.fetchTrigger$
            .pipe(
                switchMap(([report, filter]) => this.DataikuAPI.analysis.refreshPredictedImagesDataSample(report.fullModelId, this.CHUNK_SIZE, filter || {})
                    .pipe(
                        map((result) => {
                            this.onRefreshSample(result);
                            return result;
                        })
                    )
                )
            );
    }

    getSampleChunk(firstRow: number, nbRows: number): Observable<SerializedTableChunk> {
        return this.fetchTrigger$
            .pipe(
                switchMap(([report, filter]) => this.DataikuAPI.analysis.getPredictedImagesDataChunk(report.fullModelId, firstRow, nbRows, filter || {}))
            );
    }

    setFilteredPair(filter: Partial<F> | null) {
        this.filter$.next(filter);
        this.onDataChanged();
    }
}

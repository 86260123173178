import {CardResult} from './card-result';
import {Card} from './card';
import {MannKendallTest} from './../../compute/computations/timeseries/mann-kendall-test';
import {TimeSeriesCard, _TimeSeriesCard} from './time-series-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.MannKendallTestCard
 */
export interface MannKendallTestCard extends _TimeSeriesCard {
    confidenceLevel: number;

    // PolyJson type
    type: 'mann_kendall_test';
}

export namespace MannKendallTestCard {
    export const type = 'mann_kendall_test';
}

export function isMannKendallTestCard(toTest: Card): toTest is MannKendallTestCard { return MannKendallTestCard.type === toTest.type; }

export namespace MannKendallTestCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.MannKendallTestCard$MannKendallTestCardResult
     */
    export interface MannKendallTestCardResult extends TimeSeriesCard._TimeSeriesCardResult {
        intercept?: number | null;
        pValue: number;
        slope?: number | null;
        statistic: number;
        tau: number;
        trend: MannKendallTest.Trend;

        // PolyJson type
        type: 'mann_kendall_test';
    }

    export namespace MannKendallTestCardResult {
        export const type = 'mann_kendall_test';
    }

    export function isMannKendallTestCardResult(toTest: CardResult): toTest is MannKendallTestCardResult { return MannKendallTestCardResult.type === toTest.type; }
}
import {Grouping, _Grouping} from './grouping';
import {GroupingResult, _GroupingResult} from './grouping-result';

/**
 * Generated from com.dataiku.dip.eda.compute.grouping.BinnedGrouping
 */
export interface BinnedGrouping extends _Grouping {
    binSize?: number | null;
    column: string;
    keepNA: boolean;
    mode: BinnedGrouping.BinningMode;
    nbBins?: number | null;

    // PolyJson type
    type: 'binned';
}

export namespace BinnedGrouping {
    export const type = 'binned';
}

export function isBinnedGrouping(toTest: Grouping): toTest is BinnedGrouping { return BinnedGrouping.type === toTest.type; }

export namespace BinnedGrouping {
    /**
     * Generated from com.dataiku.dip.eda.compute.grouping.BinnedGrouping$BinningMode
     */
    export enum BinningMode {
        AUTO = 'AUTO',
        FIXED_NB = 'FIXED_NB',
        FIXED_SIZE = 'FIXED_SIZE'
    }

    /**
     * Generated from com.dataiku.dip.eda.compute.grouping.BinnedGrouping$BinnedGroupingResult
     */
    export interface BinnedGroupingResult extends _GroupingResult {
        column: string;
        edges: number[];
        hasNa: boolean;

        // PolyJson type
        type: 'binned';
    }

    export namespace BinnedGroupingResult {
        export const type = 'binned';
    }

    export function isBinnedGroupingResult(toTest: GroupingResult): toTest is BinnedGroupingResult { return BinnedGroupingResult.type === toTest.type; }
}
import {Grouping, _Grouping} from './grouping';
import {GroupingResult, _GroupingResult} from './grouping-result';

/**
 * Generated from com.dataiku.dip.eda.compute.grouping.MergeGrouping
 */
export interface MergeGrouping extends _Grouping {
    innerGrouping: Grouping;

    // PolyJson type
    type: 'merge';
}

export namespace MergeGrouping {
    export const type = 'merge';
}

export function isMergeGrouping(toTest: Grouping): toTest is MergeGrouping { return MergeGrouping.type === toTest.type; }

export namespace MergeGrouping {
    /**
     * Generated from com.dataiku.dip.eda.compute.grouping.MergeGrouping$MergeGroupingResult
     */
    export interface MergeGroupingResult extends _GroupingResult {
        innerGroupingResult: GroupingResult;

        // PolyJson type
        type: 'merge';
    }

    export namespace MergeGroupingResult {
        export const type = 'merge';
    }

    export function isMergeGroupingResult(toTest: GroupingResult): toTest is MergeGroupingResult { return MergeGroupingResult.type === toTest.type; }
}
import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {Curve} from './../../curves/curve';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.bivariate.FitCurve
 */
export interface FitCurve extends Computation._BivariateComputation {
    curve: Curve;

    // PolyJson type
    type: 'fit_curve';
}

export namespace FitCurve {
    export const type = 'fit_curve';
}

export function isFitCurve(toTest: Computation): toTest is FitCurve { return FitCurve.type === toTest.type; }

export namespace FitCurve {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.bivariate.FitCurve$FitCurveResult
     */
    export interface FitCurveResult extends _AvailableResult {
        parametrized: Curve.ParametrizedCurve;
        plot: FitCurve.PlotData;
        scores: FitCurve.ScoreData;

        // PolyJson type
        type: 'fit_curve';
    }

    export namespace FitCurveResult {
        export const type = 'fit_curve';
    }

    export function isFitCurveResult(toTest: ComputationResult): toTest is FitCurveResult { return FitCurveResult.type === toTest.type; }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.bivariate.FitCurve$ScoreData
     */
    export interface ScoreData {
        r2: number;
        rmse: number;
    }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.bivariate.FitCurve$PlotData
     */
    export interface PlotData {
        x: number[];
        y: number[];
    }
}